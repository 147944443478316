import React from 'react';
import PropTypes from 'prop-types';
import ChangeAccordionItem from "../components/ChangeAccordionItem";
import {useMemoizedOriginalValue, useMemoizedProposedValue} from "../helpers";
import KeySection from "../components/KeySection";

const BillingGroupChange = ({billingGroupChanged, billingGroupIndex}) => {
    const key = "changedBillingGroups[" + billingGroupIndex + "]"
    const name = useMemoizedOriginalValue(billingGroupChanged.properties, "Name");
    const npi = useMemoizedProposedValue(billingGroupChanged.keys, "NPI");
    const effectiveDate = useMemoizedProposedValue(billingGroupChanged.keys, "Effective Date");
    return (
            <ChangeAccordionItem properties={billingGroupChanged.properties} 
                                 controlId={key} 
                                 effectiveDate={effectiveDate}
                                 header={name + " : " + npi}
                                 index={billingGroupIndex}
            >
                <KeySection keys={billingGroupChanged.keys}
                            tooltip={"The keys are used to identify a unique billing group in a file and match it to what's already in Trellis"}
                />
            </ChangeAccordionItem>
    )
}
BillingGroupChange.propTypes = {
    billingGroupChange: PropTypes.object.isRequired,
    billingGroupIndex: PropTypes.number.isRequired
}
export default BillingGroupChange