import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal";
import {useForm} from "react-hook-form";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
const NewLocationVersionModal = ({showModal, onClose, locationId}) => {
    const mutation = graphql`
    mutation NewLocationVersionModalMutation($locationId: ID!, $name: String!, $description: String!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        location {
            createVersion(input: {locationId: $locationId, name: $name, description: $description, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                locationVersion {
                    id
                    location {
                        currentVersion {
                            id
                            name
                            description
                        }
                        versions {
                            ...LocationVersions_Version
                        }
                    }
                }
            }
        }
    }`
    const [commitMutation, isInFlight] = useMutation(mutation);
    const defaultValues = {
        locationId: locationId,
        name: '',
        description: '',
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31)
    };
    const schema = yup.object({
      name: yup.string().required("Name is required"),
        description: yup.string().max(1024),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    });
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
                if(data.location) {
                    toast.success("Version of Location created");
                    methods.reset();
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"New Version of Location"}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormInput controlId={"name"} label={"Name"} />
            <HorizontalFormInput controlId={"description"} label={"Description"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
NewLocationVersionModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    locationId: PropTypes.string.isRequired
}
export default NewLocationVersionModal