import React from 'react';
import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import NetworkOwnerDashboard from "../components/dashboards/network-owner";
import ProjectManagement from "../components/dashboards/project-management";
import Roles from "../pages/roles/Roles";
import RoleDetails from "../pages/roles/RoleDetails";
import NetworkOwners from "../pages/network-owners/NetworkOwners";
import UserDetails from "../pages/users/UserDetails";
import Users from "../pages/users/Users";
import NetworkOwnerDetails from "../pages/network-owners/NetworkOwnerDetails";
import Payers from "../pages/payers/Payers";
import PayerDetails from "../pages/payers/PayerDetails";
import Networks from "../pages/networks/Networks";
import NetworkDetails from "../pages/networks/NetworkDetails";
import Organizations from "../pages/organizations/Organizations";
import OrganizationDetails from "../pages/organizations/OrganizationDetails";
import Practitioners from "../pages/practitioners/Practitioners";
import PractitionerDetails from "../pages/practitioners/PractitionerDetails";
import Contracts from "../pages/contracts/Contracts";
import ContractDetails from "../pages/contracts/ContractDetails";
import Licenses from "../pages/licenses/Licenses";
import LicenseDetails from "../pages/licenses/LicenseDetails";
import Locations from "../pages/locations/Locations";
import Facilities from "../pages/facilities/Facilities";
import CardLogin from 'components/authentication/card/Login';
import relayEnvironment from "../relay/RelayEnvironment";
import {loadQuery} from 'react-relay';
import ErrorLayout from "../layouts/ErrorLayout";
import Error404 from "../components/errors/Error404";
import ProtectedRoute from "../components/common/ProtectedRoute";
import Logout from "../components/authentication/card/Logout";
import UnauthorizedError from "../components/common/UnauthorizedError";
import ContractLineOfServiceDetails from "../pages/contracts/ContractLineOfService/ContractLineOfServiceDetails";
import OrganizationContactDetails from "../pages/organizations/contacts/OrganizationContactDetails";
import RosterLoads from "../pages/roster-load/RosterLoads";
import RosterLoadDetails from "../pages/roster-load/RosterLoadDetails";
import AuthLayout from "./AuthLayout";
import {getItemFromStore, setItemToStore} from "../helpers/utils";
import BillingGroups from "../pages/billingGroups/BillingGroups";
import BillingGroupDetails from "../pages/billingGroups/BillingGroupDetails";
import LocationDetails from "../pages/locations/LocationDetails";

const options = {fetchPolicy: 'store-and-network'}
const fetchUserData = async () => {
    let response = await fetch("/account/user", {
        method: 'GET',
        headers: {
            'Content-Type':'application/json',
        }
    });
    if(response.ok) {
        const userData = await response.json();
        setItemToStore("user", JSON.stringify(userData));
        window.dispatchEvent(new StorageEvent('storage', {key:'user'}));
        return userData;
    } else {
        return null;
    }
}

const NmsRoutes = createBrowserRouter(createRoutesFromElements(
    <Route element={<AuthLayout />} path={"/"} loader={() => {
        return getItemFromStore("user", null) || fetchUserData()}
    }>
        <Route element={<CardLogin/>} path={"login"}/>
        <Route element={<ProtectedRoute/>} errorElement={<UnauthorizedError/>}>
            <Route element={<Logout/>} path={"logout"}/>
            <Route element={<MainLayout/>}>
                <Route path="/" element={<NetworkOwnerDashboard/>}
                       loader={() => {
                           const NetworksQuery = require("../pages/networks/__generated__/NetworksQuery.graphql")
                           return {networksQuery: loadQuery(relayEnvironment, NetworksQuery, {},options)};
                       }}
                />
                <Route path="dashboard/network-owner" element={<NetworkOwnerDashboard/>}
                       loader={() => {
                           const NetworksQuery = require("../pages/networks/__generated__/NetworksQuery.graphql")
                           return {networksQuery: loadQuery(relayEnvironment, NetworksQuery, {},options)};
                       }}
                />
                <Route path="dashboard/project-management" element={<ProjectManagement/>}/>
                <Route element={<ProtectedRoute permissionName={"Roles.Read"}/>}>
                    <Route path="roles" element={<Roles/>}
                           loader={() => {
                               const RolesQuery = require('../pages/roles/__generated__/RolesQuery.graphql')
                               return {roleQuery: loadQuery(relayEnvironment, RolesQuery, {}, options)}
                           }}
                    />
                    <Route path="roles/:id" element={<RoleDetails/>}
                           loader={({params}) => {
                               const RoleDetailsQuery = require('../pages/roles/__generated__/RoleDetailsQuery.graphql')
                               return {
                                   roleDetails: loadQuery(
                                       relayEnvironment,
                                       RoleDetailsQuery,
                                       {"id": params.id},
                                       options)
                               };
                           }}
                    />
                </Route>
                <Route element={<ProtectedRoute permissionName={"Users.Read"}/>}>
                    <Route path="users" element={<Users/>} 
                           loader={() => {
                               const UsersQuery = require('../pages/users/__generated__/UsersQuery.graphql');
                               return {usersQuery: loadQuery(relayEnvironment, UsersQuery, {}, options)};
                           }}
                    />
                    <Route path="users/:id" element={<UserDetails/>}
                           loader={({params}) => {
                               const UserDetailsQuery = require('../pages/users/__generated__/UserDetailsQuery.graphql');
                               return {
                                   userDetailsQuery: loadQuery(
                                       relayEnvironment,
                                       UserDetailsQuery,
                                       {
                                           "id": params.id
                                       },
                                       options)
                               };
                           }}
                    />
                </Route>
                <Route element={<ProtectedRoute permissionName={"NetworkOwners.Read"}/>}>
                    <Route path="network-owners" element={<NetworkOwners/>} 
                           loader={() => {
                               const NetworkOwnersQuery = require("../pages/network-owners/__generated__/NetworkOwnersQuery.graphql");
                               return {networkOwnersQuery: loadQuery(relayEnvironment, NetworkOwnersQuery, {}, options)}
                           }}
                    />
                    <Route path="network-owners/:id" element={<NetworkOwnerDetails/>} 
                           loader={({params}) => {
                               const NetworkOwnersDetailsQuery = require("../pages/network-owners/__generated__/NetworkOwnerDetailsQuery.graphql");
                               return {
                                   networkOwnerDetailsQuery: loadQuery(
                                       relayEnvironment,
                                       NetworkOwnersDetailsQuery,
                                       {
                                           "id":params.id
                                       },
                                       options)
                               };
                           }}
                    />
                </Route>
                <Route element={<ProtectedRoute permissionName={"Payers.Read"}/>}>
                    <Route path="payers" element={<Payers/>}/>
                    <Route path="payers/:id" element={<PayerDetails/>}/>
                </Route>
                <Route element={<ProtectedRoute permissionName={"Networks.Read"}/>}>
                    <Route path="networks" element={<Networks/>} 
                           loader={() => {
                               const NetworksQuery = require("../pages/networks/__generated__/NetworksQuery.graphql")
                               return {networksQuery: loadQuery(relayEnvironment, NetworksQuery, {},options)};
                           }}
                    />
                    <Route path="networks/:id" element={<NetworkDetails/>}
                           loader={({params}) => {
                               const NetworkDetailsQuery = require("../pages/networks/__generated__/NetworkDetailsQuery.graphql")
                               return {
                                   networkDetailsQuery: loadQuery(
                                       relayEnvironment, 
                                       NetworkDetailsQuery,
                                       {"id": params.id},
                                       options)
                               }
                           }}/>
                </Route>
                <Route element={<ProtectedRoute permissionName={"Organizations.Read"}/>}>
                    <Route path="organizations" element={<Organizations/>}
                           loader={({params}) => {
                               const OrganizationsQuery = require('../pages/organizations/__generated__/OrganizationsQuery.graphql')
                               return {organizationsQuery: loadQuery(relayEnvironment, OrganizationsQuery, {}, options)};
                           }}/>
                    <Route path="organizations/:id" element={<OrganizationDetails/>}
                           loader={({params}) => {
                               const OrganizationDetailsQuery = require('../pages/organizations/__generated__/OrganizationDetailsQuery.graphql');
                               return {
                                   organizationDetails: loadQuery(
                                       relayEnvironment,
                                       OrganizationDetailsQuery,
                                       {"id": params.id},
                                       options)
                               };
                           }}
                    />
                    <Route element={<ProtectedRoute permissionName={"OrganizationContacts.Read"}/>}>
                        <Route path={"organizations/:id/contact/:contactId"} element={<OrganizationContactDetails />}
                               loader={({params}) => {
                                   const OrganizationContactDetailsQuery = require("../pages/organizations/contacts/__generated__/OrganizationContactDetailsQuery.graphql");
                                   return {
                                       organizationContactDetailsQuery: loadQuery(
                                           relayEnvironment,
                                           OrganizationContactDetailsQuery,
                                           {"id":params.id},
                                           options)
                                   };
                               }}
                        />
                    </Route>
                </Route>
                <Route element={<ProtectedRoute permissionName={"Providers.Read"}/> }>
                    <Route path="practitioners" element={<Practitioners />}
                           loader={() => {
                               const PractitionersQuery = require("../pages/practitioners/__generated__/PractitionersQuery.graphql");
                               return { practitionersQuery: loadQuery(relayEnvironment, PractitionersQuery, {}, options)}
                           }}
                    />
                    <Route path="practitioners/:id" element={<PractitionerDetails/>}
                           loader={({params}) => {
                               const PractitionerDetailsQuery = require('../pages/practitioners/__generated__/PractitionerDetailsQuery.graphql')
                               return {practitionerDetailsQuery: loadQuery(relayEnvironment, PractitionerDetailsQuery, {"id": params.id}, options)};
                           }}
                    />
                </Route>
                <Route element={<ProtectedRoute permissionName={"Contracts.Read"}/>}>
                    <Route path="contracts" element={<Contracts/>}
                           loader={({params}) => {
                               const ContractsQuery = require('../pages/contracts/__generated__/ContractsQuery.graphql')
                               return {contractsQuery: loadQuery(relayEnvironment, ContractsQuery, {}, options)};
                           }}/>
                    <Route path="contracts/:id" element={<ContractDetails/>}
                           loader={({params}) => {
                               const ContractDetailsQuery = require('../pages/contracts/__generated__/ContractDetailsQuery.graphql')
                               return {
                                   contractDetailsQuery: loadQuery(
                                       relayEnvironment,
                                       ContractDetailsQuery,
                                       {"id": params.id},
                                       options)
                               };
                           }}/>
                    <Route element={<ProtectedRoute permissionName={"LegalContractLinesOfService.Read"}/>}>
                        <Route path={"contracts/:id/lines-of-service/:lineOfServiceId"} element={<ContractLineOfServiceDetails />}
                               loader={({params}) => {
                                   const LineOfServiceDetailsQuery = require("../pages/contracts/ContractLineOfService/__generated__/ContractLineOfServiceDetailsQuery.graphql")
                                   return {
                                       contractLineOfServiceDetailsQuery: loadQuery(
                                           relayEnvironment,
                                           LineOfServiceDetailsQuery,
                                           {"id": params.id},
                                           options)
                                   };
                               }}/>
                        </Route>
                </Route>
                <Route element={<ProtectedRoute permissionName={"Licenses.Read"}/>}>
                    <Route path="licenses" element={<Licenses/>}/>
                    <Route path="licenses/:id" element={<LicenseDetails/>}/>
                </Route>
                <Route element={<ProtectedRoute permissionName={"Locations.Read"}/>}>
                    <Route path="locations" element={<Locations/>} loader={({params}) => {
                        const LocationQuery = require("../pages/locations/__generated__/LocationsQuery.graphql")
                        return { locationQuery: loadQuery(relayEnvironment, LocationQuery, {}, options)}
                    }}/>
                    <Route path={"locations/:id"} element={<LocationDetails />} loader={({params}) => {
                        const LocationDetailsQuery = require("../pages/locations/__generated__/LocationDetailsQuery.graphql")
                        return { locationDetailsQuery: loadQuery(relayEnvironment, LocationDetailsQuery, {"id": params.id},options)};
                    }}/>
                </Route>
                <Route element={<ProtectedRoute permissionName={"Facilities.Read"}/>}>
                    <Route path="facilities" element={<Facilities/>}/>
                </Route>
                <Route element={<ProtectedRoute permissionName={"BillingGroups.Read"}/>}>
                    <Route path={"billing-groups"} element={<BillingGroups />}
                           loader={({params}) => {
                               const BillingGroupQuery = require("../pages/billingGroups/__generated__/BillingGroupsQuery.graphql")
                               return {
                                   billingGroupQuery : loadQuery(
                                       relayEnvironment,
                                       BillingGroupQuery,
                                       {},
                                       options)
                               }
                           }}
                    />
                    <Route path={"billing-groups/:id"} element={<BillingGroupDetails />} loader={({params}) => {
                        const BillingGroupDetailsQuery = require("../pages/billingGroups/__generated__/BillingGroupDetailsQuery.graphql");
                        return {
                            billingGroupDetailsQuery: loadQuery(relayEnvironment, BillingGroupDetailsQuery, {"id": params.id},options)
                    }}} />
                </Route>
                <Route path="roster-loads" element={<RosterLoads />}
                       loader={() => {
                           const RosterLoadQuery = require("../pages/roster-load/__generated__/RosterLoadsQuery.graphql");
                           return {
                               rosterLoadQuery: loadQuery(
                                   relayEnvironment,
                                   RosterLoadQuery,
                                   {},
                                   options)
                           }
                       }}
                />
                <Route path="roster-loads/:id" element={<RosterLoadDetails />} loader={({params}) => {
                    const RosterLoadDetailsQuery = require("../pages/roster-load/__generated__/RosterLoadDetailsQuery.graphql")
                    return {
                        rosterLoadDetailsQuery: loadQuery(
                            relayEnvironment,
                            RosterLoadDetailsQuery,
                            {"id": params.id},
                            options)
                    };
                }} />
            </Route>
        </Route>
        <Route element={<ErrorLayout/>}>
            <Route path={"*"} element={<Error404/>}/>
        </Route>
    </Route>
), {basename: process.env.PUBLIC_URL})
export default NmsRoutes
