import React from 'react';
import PropTypes from "prop-types";
import AddAccordionItem from "../components/AddAccordionItem";
import {useCurrentValue} from "../components/useCurrentValue";
import {useMemoizedProposedValue} from "../helpers";
import KeySection from "../components/KeySection";

const PractitionerAdd = ({practitionerAdded, practitionerIndex}) => {
    const practitionerKey = "addedPractitioners[" + practitionerIndex + "]"
    const firstName = useCurrentValue(practitionerKey,"First Name", practitionerAdded.properties);
    const lastName = useCurrentValue(practitionerKey,"Last Name", practitionerAdded.properties);
    const npi = useMemoizedProposedValue(practitionerAdded.keys, "NPI");
    const effectiveDate = useMemoizedProposedValue(practitionerAdded.keys, "Effective Date")
    return (
        <AddAccordionItem properties={practitionerAdded.properties}
                          effectiveDate={effectiveDate}
                          header={firstName + " " + lastName + " - " + npi}
                          controlId={practitionerKey}
                          index={practitionerIndex}
        >
            <KeySection keys={practitionerAdded.keys} 
                        tooltip={"The keys are used to identify unique practitioners in the file"} />
        </AddAccordionItem>
    )
}
PractitionerAdd.propTypes = {
    practitionerAdded: PropTypes.object,
    practitionerIndex: PropTypes.number.isRequired
}
export default PractitionerAdd