import React from 'react';
import PropTypes from 'prop-types';
import {useMutation} from "react-relay";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {Modal, Button} from "react-bootstrap";

const DeleteModal = ({showModal, onClose, mutation, id, thingToDelete, redirect, children}) => {
    const [commitMutation, isInFlight] = useMutation(mutation);
    const navigate = useNavigate()
    const handleDeleteClick = () => {
        commitMutation({variables: {id: id},
        onCompleted: (data) => {
            if(data.deleteNode) {
                toast.success(thingToDelete + " has been deleted")
                if(redirect) {
                    navigate(-1);
                }
                else {
                    onClose();
                }
            }
        }})
    }
    return (
        <Modal show={showModal} onHide={onClose} centered size={"lg"} aria-labelledby={"contained-modal-title-vcenter"}>
            <Modal.Header closeButton>
                <Modal.Title>Delete {thingToDelete}?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"falcon-default"} onClick={handleDeleteClick}>{isInFlight ? "Deleting" : "Delete"}</Button>
            </Modal.Footer>
        </Modal>
    )
}
DeleteModal.defaultProps = {
    redirect: false,
    id: '',
}
DeleteModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    mutation: PropTypes.object.isRequired,
    thingToDelete: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    children: PropTypes.node,
    redirect: PropTypes.bool
}
export default DeleteModal