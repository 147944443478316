import React from 'react';
import {Accordion, Card} from "react-bootstrap";
import PractitionerLocationAdd from "./PractitionerLocationAdd";
import PractitionerLocationChange from "./PractitionerLocationChange";
import {useFieldArray} from "react-hook-form";

const PractitionerLocationTab = ({practitionersByKey, locationsByKey}) => {
    const {fields} = useFieldArray({name: "addedPractitionerLocations"});
    const {fields: changedFields} = useFieldArray({name: "changedPractitionerLocations"});
    return (
        <Card>
            <Card.Body className={"bg-light"}>
                {fields && fields.length > 0 &&
                    <Accordion defaultActiveKey={fields.some(s => s.status === "Undecided") ? fields.findIndex(s => s.status === "Undecided").toString() : "0"} className={"mb-4"}>
                        {fields.map((field, index) => (
                            <PractitionerLocationAdd practitionerLocationIndex={index}
                                             practitionerLocationAdded={field}
                                             key={field.id}
                                             practitioner={practitionersByKey.get(field.parentPractitioner.id)}
                                             location={locationsByKey.get(field.parentLocation.id)}        
                            />
                        ))}
                    </Accordion>
                }
                {changedFields && changedFields.length > 0 &&
                    <Accordion defaultActiveKey={fields.some(s => s.status === "Undecided") ? changedFields.findIndex(s => s.status === "Undecided").toString() : "0"} className={"mb-4"}>
                        {changedFields.map((field, index) => {
                            return <PractitionerLocationChange practitionerLocationChanged={field}
                                                       practitionerLocationIndex={index}
                                                       key={field.id} 
                                                       practitioner={practitionersByKey.get(field.parentPractitioner.id)}
                                                       location={locationsByKey.get(field.parentLocation.id)}
                            />
                        })}
                    </Accordion>
                }
            </Card.Body>
        </Card>
    )
}
export default PractitionerLocationTab