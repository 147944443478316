import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Accordion, Table} from "react-bootstrap";
import AccordionContext from 'react-bootstrap/AccordionContext';
import {useWatch} from "react-hook-form";
import ChangeField from "./ChangeField";
import AcceptReject from "./AcceptReject";
import StatusBadge from "./StatusBadge";

const ChangeAccordionItem = ({index, controlId, properties, header, effectiveDate, ...props}) => {
    const status = useWatch({name: `${controlId}.status`});
    const { activeEventKey } = useContext(AccordionContext);
    return (
        <Accordion.Item eventKey={index.toString()}>
            <Accordion.Header>
                Change: {header} as of {effectiveDate}
                <StatusBadge status={status} />
            </Accordion.Header>
            <Accordion.Body>
                {props.children}
                <Table size={"sm"} striped className={"fs--1 mb-0"}>
                    <thead className="bg-200 text-900 text-nowrap align-middle">
                    <tr>
                        <th scope={"col"}>Property Name</th>
                        <th scope={"col"}>Original Value</th>
                        <th scope={"col"}>New Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {properties.map((p, i) => {
                        return <ChangeField 
                            key={i}
                            controlId={`${controlId}.properties[${i}]`}
                            property={p}
                            status={status}
                            expanded={activeEventKey === index.toString()}
                        />
                    }).sort((a, b) => {return a.props.property.displayOrder - b.props.property.displayOrder})}
                    </tbody>
                </Table>
                <AcceptReject controlId={controlId} />
            </Accordion.Body>
        </Accordion.Item>
    )
}
ChangeAccordionItem.defaultProps = {}
ChangeAccordionItem.propTypes = {}
export default ChangeAccordionItem