import React from 'react';
import {Accordion, Card} from "react-bootstrap";
import PractitionerAdd from "./PractitionerAdd";
import PractitionerChange from "./PractitionerChange";
import {useFieldArray} from "react-hook-form";

const PractitionerTab = (props) => {
    const {fields} = useFieldArray({name: "addedPractitioners"});
    const {fields: changedFields} = useFieldArray({name: "changedPractitioners"});
    return (
        <Card>
            <Card.Body className={"bg-light"}>
                {fields && fields.length > 0 &&
                    <Accordion defaultActiveKey={fields.some(s => s.status === "Undecided") ? fields.findIndex(s => s.status === "Undecided").toString() : "0"} className={"mb-4"}>
                        {fields.map((field, index) => (
                            <PractitionerAdd practitionerIndex={index}
                                             practitionerAdded={field}
                                             key={field.id}
                            />
                        ))}
                    </Accordion>
                }
                {changedFields && changedFields.length > 0 &&
                    <Accordion defaultActiveKey={fields.some(s => s.status === "Undecided") ? changedFields.findIndex(s => s.status === "Undecided").toString() : "0"} className={"mb-4"}>
                        {changedFields.map((field, index) => {
                            return <PractitionerChange practitionerChanged={field}
                                                       practitionerIndex={index}
                                                       key={field.id}
                            />
                        })}
                    </Accordion>
                }
            </Card.Body>
        </Card>
    )
}
export default PractitionerTab