/**
 * @generated SignedSource<<df4bb878451a0d0f0da6a047bb046a75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "languageId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "practitionerId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "seq"
},
v5 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "languageId",
        "variableName": "languageId"
      },
      {
        "kind": "Variable",
        "name": "practitionerId",
        "variableName": "practitionerId"
      },
      {
        "kind": "Variable",
        "name": "seq",
        "variableName": "seq"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewPractitionerLanguageModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerMutationGroup",
        "kind": "LinkedField",
        "name": "practitioner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "PractitionerLanguageModifyPayload",
            "kind": "LinkedField",
            "name": "createLanguage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerLanguage",
                "kind": "LinkedField",
                "name": "practitionerLanguage",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Practitioner",
                    "kind": "LinkedField",
                    "name": "practitioner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerLanguage",
                        "kind": "LinkedField",
                        "name": "languages",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PractitionerLanguages_Languages"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewPractitionerLanguageModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerMutationGroup",
        "kind": "LinkedField",
        "name": "practitioner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "PractitionerLanguageModifyPayload",
            "kind": "LinkedField",
            "name": "createLanguage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerLanguage",
                "kind": "LinkedField",
                "name": "practitionerLanguage",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Practitioner",
                    "kind": "LinkedField",
                    "name": "practitioner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerLanguage",
                        "kind": "LinkedField",
                        "name": "languages",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Language",
                            "kind": "LinkedField",
                            "name": "language",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isoCode",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayOrder",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "seq",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e151f723c02e9204f6c3d1ba6907f8de",
    "id": null,
    "metadata": {},
    "name": "NewPractitionerLanguageModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewPractitionerLanguageModalMutation(\n  $practitionerId: ID!\n  $languageId: ID!\n  $seq: Int!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  practitioner {\n    createLanguage(input: {practitionerId: $practitionerId, languageId: $languageId, seq: $seq, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      practitionerLanguage {\n        id\n        practitioner {\n          languages {\n            ...PractitionerLanguages_Languages\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment PractitionerLanguages_Languages on PractitionerLanguage {\n  id\n  language {\n    id\n    name\n    isoCode\n    displayOrder\n  }\n  seq\n  effectiveFrom\n  effectiveTo\n}\n"
  }
};
})();

node.hash = "9fa53b7c7df34f2af830ca9d378522d4";

export default node;
