import React from 'react';
import PropTypes from 'prop-types';
import FormModal from "../../../components/common/modals/FormModal";
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allLanguagesDropdown} from "../../../relay/Fragments";
import {sortByDisplayOrder} from "../../../helpers/utils";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
const NewPractitionerLanguageModal = ({showModal, onClose, practitionerId, languages}) => {
    const mutation = graphql`
    mutation NewPractitionerLanguageModalMutation($practitionerId: ID!, $languageId: ID!, $seq: Int!, $effectiveFrom: DateOnly!,
    $effectiveTo:DateOnly!) {
        practitioner {
            createLanguage(input: {practitionerId: $practitionerId, languageId: $languageId, seq: $seq, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                practitionerLanguage {
                    id
                    practitioner {
                        languages {
                            ...PractitionerLanguages_Languages
                        }}}}}}`
    const allLanguages = useFragment(allLanguagesDropdown, languages).sort(sortByDisplayOrder).map((l) => {return {label: l.name, value: l.id}});
    const [commitMutation, isInFlight] =  useMutation(mutation);
    const defaultValues = {
        practitionerId: practitionerId,
        languageId: '',
        seq: '',
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31)
    }
    const schema = yup.object({
        languageId: yup.string().required("Language is required"),
        seq: yup.number().required("Seq is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    });
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});

    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
                if(data.practitioner) {
                    toast.success("Language for Provider created")
                    methods.reset();
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"New Language"}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormSelect options={allLanguages} controlId={"languageId"} label={"Language"} />
            <HorizontalFormInput controlId={"seq"} label={"Seq"} type={"number"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
NewPractitionerLanguageModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    practitionerId: PropTypes.string.isRequired,
    languages: PropTypes.array.isRequired,
}
export default NewPractitionerLanguageModal