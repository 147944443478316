import React from 'react';
import ContractInfo from "./ContractInfo";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import {useLoaderData} from "react-router-dom";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import ContractAttachments from "./ContractAttachments/ContractAttachments";
import ContractLinesOfService from "./ContractLineOfService/ContractLinesOfService";
import ContractTerms from "./ContractTerms/ContractTerms";
import RosterLoadTable from "../roster-load/RosterLoadTable";
import {useUser} from "../../hooks/useUser";
import DeletePanel from "../../components/common/repeated-page-panels/DeletePanel";
const ContractDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
        query ContractDetailsQuery($id: ID!) {
            node(id: $id) {
                ... on LegalContract {
                    id
                    name
                    startDate
                    endDate
                    network {
                        id
                        linesOfService {
                            ...LinesOfServiceFragments_LineOfServiceDropdown
                        }
                    }
                    ...ContractInfo_Contract
                    attachments {
                        ...ContractAttachments_Attachments
                    }
                    linesOfService {
                        ...ContractLinesOfServiceFragments_LinesOfService
                    }
                    terms {
                        ...ContractTerms_Terms
                    }
rosterLoads {
                    ...RosterLoadTable_RosterLoad
                }
                }
            }
            allOrganizations {
                ...OrganizationsFragments_AllOrganizationsDropdown
            }
            allNetworks {
                ...NetworkFragments_allNetworkDropdown
            }
            allTermTypes {
                id
                description
                valueType
                ...TermTypesFragments_TermTypeDropdown
            }
            allAttachmentCategories {
                id
                name
                displayOrder
                ...Fragments_AllAttachmentCategoriesDropdown
            }
        }
    `, loader.contractDetailsQuery)
    const deleteMutation = graphql`
    mutation ContractDetailsDeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            id
        }}`;
    const {hasPermission} = useUser();
    return (
        <>
            <DetailsPageHeader iconText={"Contract"} iconType={"pen-nib"} title={data.node.name} />
            <ContractInfo contract={data.node} networks={data.allNetworks} organizations={data.allOrganizations} />
            <ContractTerms terms={data.node.terms} contractId={data.node.id} possibleTermTypes={data.allTermTypes}/>
            <ContractAttachments attachments={data.node.attachments} contractId={data.node.id} possibleAttachmentCategories={data.allAttachmentCategories}/>
            <ContractLinesOfService linesOfService={data.node.linesOfService}
                                    contractId={data.node.id}
                                    possibleLinesOfService={data.node.network.linesOfService}
                                    contractStartDate={data.node.startDate}
                                    contractEndDate={data.node.endDate}
            />
            {hasPermission("RosterLoads.Read") && 
                <RosterLoadTable 
                    linesOfService={data.node.linesOfService} 
                    rosterLoad={data.node.rosterLoads}
                    allowNew={true}
                />}
            {hasPermission("Contracts.Delete") && 
                <DeletePanel mutation={deleteMutation} redirectUrl={"/contracts"} thingToDelete={"Contract"} id={data.node.id}>
                    In order to delete a Contract, there must be no data associated with it.
            </DeletePanel>
            }
        </>
    );
};

export default ContractDetails;
