import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {allContractsDropdown} from "../contracts/ContractFragments";
import {useFragment} from "react-relay";
import {useForm} from "react-hook-form";
import NewModal from "../../components/common/modals/NewModal";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormSelect from "../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import {allCountiesDropdown} from "../../relay/Fragments";
import HorizontalFormAsyncSelect from "../../components/common/horizontal-form-inputs/HorizontalFormAsyncSelect";

const NewLocationModal = ({showModal, onClose, contracts}) => {
    const NewLocationMutation = graphql`
        mutation NewLocationModalMutation($name: String!, $description: String!, $addressLine1: String!, $addressLine2: String!,
        $city: String!, $state: String!, $postalCode: String!, $legalContractId: ID!) {
            location {
                create(input: {name: $name, description: $description, addressLine1: $addressLine1, addressLine2: $addressLine2, city: $city, 
                state: $state, postalCode: $postalCode, legalContractId: $legalContractId}) {
                    location {
                        id
                    }
                }
            }
        }`
    const allContracts = useFragment(allContractsDropdown, contracts).map(c => {return {value: c.id, label: c.name}});
    const defaultValues = {
        name: '',
        description:'',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        legalContractId: ''
    }
    const methods = useForm({defaultValues: defaultValues})
    
    return (
        <NewModal showModal={showModal} 
                  onClose={onClose}
                  methods={methods}
                  title={"New Location"}
                  redirectUrl={"/locations/"}
                  mutation={NewLocationMutation}
                  returnedIdKey={"location.create.location.id"}
        >
            <HorizontalFormInput controlId={"name"} label={"Name"} validation={{required: "Name is required"}} autoComplete={"off"} />
            <HorizontalFormInput controlId={"description"} label={"Description"} />
            <HorizontalFormSelect options={allContracts} controlId={"legalContractId"} label={"Contract"} validation={{required: "Contract is required"}} />
            <HorizontalFormInput controlId={"addressLine1"} label={"Address Line 1"} validation={{required: "Address Line 1 is required"}} autoComplete={"off"} />
            <HorizontalFormInput controlId={"addressLine2"} label={"Address Line 2"} autoComplete={"off"} />
            <HorizontalFormInput controlId={"city"} label={"City"} validation={{required: "City is required"}} />
            <HorizontalFormInput controlId={"state"} label={"State"} validation={{required: "State is required", maxLength: {value: 2, message:"Please use the abbreviation for the state"}}} />
            <HorizontalFormInput controlId={"postalCode"} label={"Postal Code"} validation={{required: "Postal Code is required", maxLength: 9}} />
        </NewModal>
    )
}
NewLocationModal.defaultProps = {}
NewLocationModal.propTypes = {}
export default NewLocationModal