import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {addEditAndDeleteCells, DateCell} from "../../../components/common/advance-table/CustomCells";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {useUser} from "../../../hooks/useUser";
import NewPractitionerIdentifierModal from "./NewPractitionerIdentifierModal";
import EditPractitionerIdentifierModal from "./EditPractitionerIdentifierModal";
import DeleteModal from "../../../components/common/modals/DeleteModal";

const columns = [
    {accessor: 'identifierType.display', Header: 'System'},
    {accessor: 'identifierUseCode.display', Header:'Use'},
    {accessor: 'value', Header: 'Value'},
    {accessor: 'effectiveFrom', Header: "Effective From", Cell: DateCell},
    {accessor:'effectiveTo', Header:'Effective To', Cell: DateCell}
    ];
const PractitionerIdentifiers = ({practitionerId, identifiers,  allIdentifierTypes, allIdentifierUseCodes}) => {
    const deleteMutation = graphql`
    mutation PractitionerIdentifiersDeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            owner {
                ... on Practitioner {
                    id
                    identifiers {
                        id
                    }
                }
            }
        }
    }`
    const data = useFragment(graphql`
        fragment PractitionerIdentifiers_Identifiers on PractitionerIdentifier @relay(plural:true) {
            id
            identifierType {
                display
                id
            }
            identifierUseCode {
                display
                id
            }
            value
            effectiveFrom
            effectiveTo
        }
    `, identifiers)
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedIdentifier, setSelectedIdentifier] = useState(null);
    
    const handleEditClick = (data) => {
        setSelectedIdentifier(data);
        setShowEditModal(true);
    }
    const handleDeleteClick = (data) => {
        setSelectedIdentifier(data);
        setShowDeleteModal(true);
    }

    const cols = useMemo(() => {
        const canEdit = hasPermission("ProviderIdentifiers.Update");
        const canDelete = hasPermission("ProviderIdentifiers.Delete");
        return addEditAndDeleteCells(canEdit, canDelete, columns, handleEditClick, handleDeleteClick)
    }, [hasPermission])
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                         title={"Identifiers"}
                                         table
                                         showNew={hasPermission("ProviderIdentifiers.Create")} />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewPractitionerIdentifierModal showModal={showNewModal} 
                                            onClose={() => {setShowNewModal(false);}}
                                            identifierTypes={allIdentifierTypes}
                                            identifierUseCodes={allIdentifierUseCodes}
                                            practitionerId={practitionerId}
            />
            {selectedIdentifier && <EditPractitionerIdentifierModal showModal={showEditModal} 
                                                                    onClose={() => {setShowEditModal(false)}}
                                                                    selectedIdentifier={selectedIdentifier}
                                                                    identifierTypes={allIdentifierTypes}
                                                                    identifierUseCodes={allIdentifierUseCodes}
            />}
            {selectedIdentifier && <DeleteModal mutation={deleteMutation} 
                                                onClose={() => {setShowDeleteModal(false)}}
                                                thingToDelete={"Identifier"} 
                                                showModal={showDeleteModal} 
                                                id={selectedIdentifier.id}>
                <p>This action cannot be undone.</p>
            </DeleteModal>}
        </>
    )
}
PractitionerIdentifiers.propTypes = {}
export default PractitionerIdentifiers