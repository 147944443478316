/**
 * @generated SignedSource<<61da968c8b6eeb6abfc9928552c6c7c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "middleInitial"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nameSuffix"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nickName"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "npi"
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PractitionerMutationGroup",
    "kind": "LinkedField",
    "name": "practitioner",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "firstName",
                "variableName": "firstName"
              },
              {
                "kind": "Variable",
                "name": "lastName",
                "variableName": "lastName"
              },
              {
                "kind": "Variable",
                "name": "legalContractId",
                "variableName": "contractId"
              },
              {
                "kind": "Variable",
                "name": "middleInitial",
                "variableName": "middleInitial"
              },
              {
                "kind": "Variable",
                "name": "nameSuffix",
                "variableName": "nameSuffix"
              },
              {
                "kind": "Variable",
                "name": "nickName",
                "variableName": "nickName"
              },
              {
                "kind": "Variable",
                "name": "npi",
                "variableName": "npi"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "PractitionerModifyPayload",
        "kind": "LinkedField",
        "name": "create",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Practitioner",
            "kind": "LinkedField",
            "name": "practitioner",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewPractitionerModalMutation",
    "selections": (v7/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewPractitionerModalMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "a8b05e934bf627fcb188dc2cf83b511b",
    "id": null,
    "metadata": {},
    "name": "NewPractitionerModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewPractitionerModalMutation(\n  $contractId: ID!\n  $firstName: String!\n  $lastName: String!\n  $npi: String!\n  $middleInitial: String!\n  $nameSuffix: String!\n  $nickName: String!\n) {\n  practitioner {\n    create(input: {legalContractId: $contractId, firstName: $firstName, lastName: $lastName, middleInitial: $middleInitial, nameSuffix: $nameSuffix, nickName: $nickName, npi: $npi}) {\n      practitioner {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "eefa8077659215f21205130eb85bc631";

export default node;
