import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allIdentifierTypesDropdown, allIdentifierUseCodesDropdown} from "../../../relay/Fragments";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal";
const NewBillingGroupIdentifierModal = ({showModal, onClose, billingGroupId, identifierTypes, identifierUseCodes}) => {
    const IdentifierCreateMutation = graphql`
    mutation NewBillingGroupIdentifierModalMutation($billingGroupId: ID!, $identifierTypeId: ID!, $identifierUseCodeId: ID!, $value: String!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        billingGroup {
            createIdentifier(input:{billingGroupId: $billingGroupId, identifierTypeId: $identifierTypeId, identifierUseCodeId: $identifierUseCodeId, value: $value, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo} ) {
                billingGroupIdentifier {
                    id
                    billingGroup {
                        id
                        identifiers {
                            ...BillingGroupIdentifiers_Identifiers
                        }
                    }
                }
            }
        }
    }`
    const idTypes = useFragment(allIdentifierTypesDropdown,identifierTypes).map((id) => {return {value: id.id, label: id.display}});
    const idUseCodes = useFragment(allIdentifierUseCodesDropdown, identifierUseCodes).map((id) => {return {value: id.id, label: id.display}});
    
    const [commitMutation, isInFlight] = useMutation(IdentifierCreateMutation);
    const defaultValues = {
        billingGroupId: billingGroupId,
        identifierTypeId: '',
        identifierUseCodeId: '',
        value: '',
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31)
    };
    const schema = yup.object({
        identifierTypeId: yup.string().required("Identifier Type is required"),
        identifierUseCodeId: yup.string().required("Identifier Use Code is required"),
        value: yup.string().required("Value is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    });
    
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    
    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
            if(data.billingGroup) {
                toast.success("Identifier for Billing Group created")
                methods.reset();
                onClose();
            }
            }})
    }
    return (
        <FormModal showModal={showModal} 
                   onClose={onClose} 
                   title={"New Identifier"} 
                   methods={methods} 
                   isInFlight={isInFlight} 
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormSelect options={idTypes} controlId={"identifierTypeId"} label={"Identifier Type"} />
            <HorizontalFormSelect options={idUseCodes} controlId={"identifierUseCodeId"} label={"Identifier Use Code"} />
            <HorizontalFormInput controlId={"value"} label={"Value"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
NewBillingGroupIdentifierModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    billingGroupId: PropTypes.string.isRequired,
    identifierTypes: PropTypes.array.isRequired,
    identifierUseCodes: PropTypes.array.isRequired
}
export default NewBillingGroupIdentifierModal