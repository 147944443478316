import React from 'react';
import PropTypes from 'prop-types';
import FormModal from "../../../components/common/modals/FormModal";
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allIdentifierTypesDropdown, allIdentifierUseCodesDropdown} from "../../../relay/Fragments";
import dayjs from "dayjs";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
const EditPractitionerIdentifierModal = ({showModal, onClose, identifierTypes, identifierUseCodes, selectedIdentifier}) => {
    const mutation = graphql`
    mutation EditPractitionerIdentifierModalMutation($id: ID!, $identifierTypeId: ID!, $identifierUseCodeId: ID!, $value: String!,
        $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        practitioner {
            modifyIdentifier(input: {practitionerIdentifierId: $id, identifierTypeId: $identifierTypeId, identifierUseCodeId: $identifierUseCodeId,
            value: $value, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                practitionerIdentifier {
                    id
                    practitioner {
                        id
                        identifiers {
                            ...PractitionerIdentifiers_Identifiers
                        }}}}}}`
    const idTypes = useFragment(allIdentifierTypesDropdown, identifierTypes).map((id) => {return {value: id.id, label:id.display}});
    const idUseCodes = useFragment(allIdentifierUseCodesDropdown, identifierUseCodes).map((id) => {return {value: id.id, label: id.display}});
    const [commitMutation, isInFlight] = useMutation(mutation);
    const effectiveFrom = selectedIdentifier?.effectiveFrom ? dayjs(selectedIdentifier?.effectiveFrom).toDate() : '';
    const effectiveTo = selectedIdentifier?.effectiveTo ? dayjs(selectedIdentifier?.effectiveTo).toDate() : '';
    const defaultValues = {
        id: selectedIdentifier?.id,
        effectiveFrom: effectiveFrom,
        effectiveTo: effectiveTo,
        value:selectedIdentifier?.value,
        identifierTypeId: selectedIdentifier?.identifierType?.id,
        identifierUseCodeId: selectedIdentifier?.identifierUseCode?.id
    }
    const schema = yup.object({
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From"),
        value: yup.string().required("Value is required"),
        identifierTypeId: yup.string().required("Identifier Type is required"),
        identifierUseCodeId: yup.string().required("Identifier Use Code is required")
    });
    const methods = useForm({values: defaultValues, resolver: yupResolver(schema)});

    const handleSubmit = (data) => {
        commitMutation({variables: {...data},
            onCompleted: (data) => {
                if(data.practitioner) {
                    toast.success("Identifier has been updated")
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   methods={methods}
                   onSubmit={handleSubmit}
                   isInFlight={isInFlight}
                   title={"Edit Identifier"}
        >
            <HorizontalFormSelect options={idTypes} controlId={"identifierTypeId"} label={"Identifier Type"} />
            <HorizontalFormSelect options={idUseCodes} controlId={"identifierUseCodeId"} label={"Identifier Use Code"} />
            <HorizontalFormInput controlId={"value"} label={"Value"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
EditPractitionerIdentifierModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    identifierTypes: PropTypes.array.isRequired,
    identifierUseCodes: PropTypes.array.isRequired,
    selectedIdentifier: PropTypes.object.isRequired
}
export default EditPractitionerIdentifierModal