import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {
    addEditAndDeleteCells,
    DateCell,
    PhoneCell
} from "../../../components/common/advance-table/CustomCells";
import {useUser} from "../../../hooks/useUser";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import NewBillingGroupAddressModal from "./NewBillingGroupAddressModal";
import EditBillingGroupAddressModal from "./EditBillingGroupAddressModal";
import DeleteModal from "../../../components/common/modals/DeleteModal";
import {FormatAddress} from "../../../helpers/utils";

const columns = [
    {accessor: "addressUseCode.display", Header: "Use"},
    {accessor: "addressLine1", Header: "Address",
        Cell: (rowData) => {
            const address = rowData.row.original
            return <>{FormatAddress(address.addressLine1, address.addressLine2, address.addressLine3, 
                address.city,address.state,address.postalCode)}</>
        }},
    {accessor: "phone", Header: "Phone Number", Cell: PhoneCell},
    {accessor: "effectiveFrom", Header: "Effective From", Cell: DateCell},
    {accessor: "effectiveTo", Header: "Effective To", Cell: DateCell}
]

const BillingGroupAddress = ({addresses, billingGroupId, addressUseCodes}) => {
    const mutation = graphql`
    mutation BillingGroupAddressDeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            owner {
                ... on BillingGroup {
                    id 
                    addresses {
                        id
                    }
                }
            }
        }
    }`
    const data = useFragment(graphql`
        fragment BillingGroupAddress_Address on BillingGroupAddress @relay(plural:true) {
            id
            addressUseCode {
                display
                id
            }
            addressLine1
            addressLine2
            addressLine3
            city
            state
            postalCode
            county
            {
                id
                name
                state
            }
            phoneNumber
            effectiveFrom
            effectiveTo
        }`, addresses);
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState({});
    
    const handleEditClick = (data) => {
        setSelectedAddress(data);
        setShowEditModal(true);
    }
    const handleDeleteClick = (data) => {
        setSelectedAddress(data);
        setShowDeleteModal(true);
    }
    
    const cols = useMemo(() => {
        const canEdit = hasPermission("BillingGroupAddresses.Update");
        const canDelete = hasPermission("BillingGroupAddresses.Delete");
        return addEditAndDeleteCells(canEdit, canDelete, columns, handleEditClick, handleDeleteClick)
    }, [hasPermission])
    
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                         title={"Addresses"}
                                         table
                                         showNew={hasPermission("BillingGroupAddresses.Create")}
                        />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                                      rowClassName="align-middle white-space-nowrap"
                                      tableProps={{
                                          size: 'sm',
                                          striped: true,
                                          className: 'fs--1 mb-0 overflow-hidden'}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewBillingGroupAddressModal onClose={() => {setShowNewModal(false)}} 
                                         billingGroupId={billingGroupId}
                                         showModal={showNewModal} 
                                         addressUseCodes={addressUseCodes}
            />
            <EditBillingGroupAddressModal onClose={() => {setShowEditModal(false)}} 
                                          selectedAddress={selectedAddress}
                                          addressUseCodes={addressUseCodes}
                                          showModal={showEditModal}
            />
            <DeleteModal mutation={mutation} 
                         onClose={() => {setShowDeleteModal(false)}} 
                         thingToDelete={"Address"} 
                         showModal={showDeleteModal}
                         id={selectedAddress?.id}
            >
                <p>Are you sure you want to delete this Address: {FormatAddress(selectedAddress?.addressLine1, selectedAddress?.addressLine2,
                    selectedAddress?.addressLine3, selectedAddress?.city, selectedAddress?.state, selectedAddress?.postalCode)}</p>
            </DeleteModal>
        </>
    )
}
BillingGroupAddress.propTypes = {
    addresses: PropTypes.array.isRequired,
    billingGroupId: PropTypes.string.isRequired,
    addressUseCodes: PropTypes.array.isRequired
}
export default BillingGroupAddress