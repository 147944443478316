/**
 * @generated SignedSource<<0686c1a1aef323b93cefdf6c7ffa66a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "effectiveFrom",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "effectiveTo",
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "display",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LocationDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LocationVersion",
                "kind": "LinkedField",
                "name": "currentVersion",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LocationInfo_Location"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LocationVersion",
                "kind": "LinkedField",
                "name": "versions",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "LocationVersions_Version"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LocationContactPoint",
                "kind": "LinkedField",
                "name": "contactPoints",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "LocationContactPoints_ContactPoints"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Location",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "allLegalContracts",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContractFragments_AllContractsDropdown"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "County",
        "kind": "LinkedField",
        "name": "allCounties",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Fragments_AllCountiesDropdown"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactPointSystemCode",
        "kind": "LinkedField",
        "name": "allContactPointSystemCodes",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Fragments_AllContactPointSystemCodesDropdown"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactPointUseCode",
        "kind": "LinkedField",
        "name": "allContactPointUseCodes",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Fragments_AllContactPointUseCodesDropdown"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LocationDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LocationVersion",
                "kind": "LinkedField",
                "name": "currentVersion",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContract",
                "kind": "LinkedField",
                "name": "legalContract",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addressLine1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addressLine2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "County",
                "kind": "LinkedField",
                "name": "county",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "longitude",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "latitude",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LocationVersion",
                "kind": "LinkedField",
                "name": "versions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numberOfBeds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adaAccessible",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canProvideMedicationsToTreatOpioidDisorders",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LocationContactPoint",
                "kind": "LinkedField",
                "name": "contactPoints",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactPointSystemCode",
                    "kind": "LinkedField",
                    "name": "contactPointSystemCode",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactPointUseCode",
                    "kind": "LinkedField",
                    "name": "contactPointUseCode",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Location",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "allLegalContracts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "County",
        "kind": "LinkedField",
        "name": "allCounties",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fipsCountyCode",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactPointSystemCode",
        "kind": "LinkedField",
        "name": "allContactPointSystemCodes",
        "plural": true,
        "selections": (v8/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactPointUseCode",
        "kind": "LinkedField",
        "name": "allContactPointUseCodes",
        "plural": true,
        "selections": (v8/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9fb9f5c56d6617f062bcb5d2f7bd53a3",
    "id": null,
    "metadata": {},
    "name": "LocationDetailsQuery",
    "operationKind": "query",
    "text": "query LocationDetailsQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    id\n    ... on Location {\n      currentVersion {\n        name\n        id\n      }\n      ...LocationInfo_Location\n      versions {\n        ...LocationVersions_Version\n        id\n      }\n      contactPoints {\n        ...LocationContactPoints_ContactPoints\n        id\n      }\n    }\n  }\n  allLegalContracts {\n    ...ContractFragments_AllContractsDropdown\n    id\n  }\n  allCounties {\n    ...Fragments_AllCountiesDropdown\n    id\n  }\n  allContactPointSystemCodes {\n    ...Fragments_AllContactPointSystemCodesDropdown\n    id\n  }\n  allContactPointUseCodes {\n    ...Fragments_AllContactPointUseCodesDropdown\n    id\n  }\n}\n\nfragment ContractFragments_AllContractsDropdown on LegalContract {\n  id\n  name\n}\n\nfragment Fragments_AllContactPointSystemCodesDropdown on ContactPointSystemCode {\n  id\n  display\n}\n\nfragment Fragments_AllContactPointUseCodesDropdown on ContactPointUseCode {\n  id\n  display\n}\n\nfragment Fragments_AllCountiesDropdown on County {\n  id\n  name\n  fipsCountyCode\n  state\n}\n\nfragment LocationContactPoints_ContactPoints on LocationContactPoint {\n  id\n  contactPointSystemCode {\n    id\n    display\n  }\n  contactPointUseCode {\n    id\n    display\n  }\n  value\n  effectiveFrom\n  effectiveTo\n}\n\nfragment LocationInfo_Location on Location {\n  id\n  legalContract {\n    id\n  }\n  currentVersion {\n    name\n    id\n  }\n  addressLine1\n  addressLine2\n  city\n  state\n  postalCode\n  county {\n    id\n  }\n  longitude\n  latitude\n}\n\nfragment LocationVersions_Version on LocationVersion {\n  id\n  name\n  description\n  numberOfBeds\n  adaAccessible\n  canProvideMedicationsToTreatOpioidDisorders\n  effectiveFrom\n  effectiveTo\n}\n"
  }
};
})();

node.hash = "6c4b68b332344fa85d1d9e7faacb7e92";

export default node;
