import React from 'react';
import {useFieldArray} from "react-hook-form";
import {Accordion, Card} from "react-bootstrap";
import LocationAdd from "./LocationAdd";
import LocationChange from "./LocationChange";

const LocationTab = (props) => {
    const {fields} = useFieldArray({name: "addedLocations"})
    const {fields: changedFields} = useFieldArray({name: "changedLocations"});
    return (
        <Card>
            <Card.Body className={"bg-light"}>
                {fields && fields.length > 0 &&
                    <Accordion defaultActiveKey={"0"} className={"mb-4"}>
                        {fields.map((field, index) => (
                            <LocationAdd locationIndex={index} locationAdded={field} key={index} />
                        ))}
                    </Accordion>
                }
                {changedFields && changedFields.length > 0 &&
                    <Accordion defaultActiveKey={fields.some(s => s.status === "Undecided") ? changedFields.findIndex(s => s.status === "Undecided").toString() : "0"} className={"mb-4"}>
                        {changedFields.map((field, index) => {
                            return <LocationChange locationChanged={field} locationIndex={index} key={field.id} />
                        })}
                    </Accordion>
                }
            </Card.Body>
        </Card>
    )
}
export default LocationTab