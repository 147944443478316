/**
 * @generated SignedSource<<3539dc78e78c1a41b209e23eed66bbdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RosterLoadMutationGroup",
    "kind": "LinkedField",
    "name": "rosterLoad",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "rosterLoadId",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "RosterLoadModifyPayload",
        "kind": "LinkedField",
        "name": "commitRosterLoad",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RosterLoad",
            "kind": "LinkedField",
            "name": "rosterLoad",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fileName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InProgressRosterLoadCommitMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InProgressRosterLoadCommitMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d91dca41326bcb763416dda45b2e98d9",
    "id": null,
    "metadata": {},
    "name": "InProgressRosterLoadCommitMutation",
    "operationKind": "mutation",
    "text": "mutation InProgressRosterLoadCommitMutation(\n  $id: ID!\n) {\n  rosterLoad {\n    commitRosterLoad(input: {rosterLoadId: $id}) {\n      rosterLoad {\n        id\n        fileName\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "3915fafca9071ac06775249cb716257b";

export default node;
