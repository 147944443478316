import React from 'react';
import PropTypes from 'prop-types';
import {useLoaderData} from "react-router-dom";
import {DateCell, LinkCell} from "../../components/common/advance-table/CustomCells";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";

const columns = [
    {
        accessor: "fileName",
        Header: "Filename",
        Cell: rowData => {
            const {fileName, id} = rowData.row.original;
            return <LinkCell name={fileName} to={'/roster-loads/' + id} hasAvatar />
        }
    },
    {
        accessor: "status",
        Header: "Status"
    },
    {
      accessor: "createdOn",
        Header: "When Created",
        Cell: DateCell
        
    },
    {
        accessor: "createdBy.userName",
        Header: "Who Created"
    }
    
]
const RosterLoads = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
        query RosterLoadsQuery {
            allRosterLoads {
                id
                fileName
                status
                createdBy {
                    userName
                }
                createdOn
            }
        }
    `, loader.rosterLoadQuery)
    return (
        <>
            <AdvanceTableWrapper columns={columns}
                                 data={data.allRosterLoads}
                                 sortable
                                 pagination
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <IndexTableHeader name={"Roster Loads"} 
                                          table 
                                          onNewClick={() => {}} 
                                          showNew={false}
                        />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                                      rowClassName="align-middle white-space-nowrap"
                                      tableProps={{
                                          size: 'sm',
                                          striped: true,
                                          className: 'fs--1 mb-0 overflow-hidden'}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter rowCount={data.allRosterLoads.length}
                                            table
                                            rowInfo
                                            navButtons
                                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}
export default RosterLoads