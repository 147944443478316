import {useMemo, useState} from 'react'
import {useController} from "react-hook-form";
import {conformValue, createInitOptions, translateMask} from "../helpers";
import {useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";

const propertyMutation = graphql`
    mutation usePropertyFieldMutation($id: ID!, $proposedValue: StringInput) {
        rosterLoad {
            modifyRosterLoadProperty(input: {id: $id, proposedValue: $proposedValue}) {
                property {
                    id
                    proposedValue
                }
            }
        }
    }`

const propertyFragment = graphql`
    fragment usePropertyFieldFragment on RosterLoadProperty {
        id
        propertyName
        proposedValue
        originalValue
        displayOrder
        required
        isKey
        mask {
            type
            value
        }
        pattern
        patternErrorMessage
        possibleValues {
            showsUp
            value
        }
    }
`
export const usePropertyField = (controlId, property) => {
    let validationRules = {}
    if(property.required) {
        validationRules.required = `${property.propertyName} is required`;
    }
    if(property.pattern) {
        validationRules.pattern = {value: new RegExp(property.pattern), message: property.patternErrorMessage};
    }
    const mask = useMemo(() => {return translateMask(property.mask)},[property]);
    const {field, fieldState: {invalid, error}} = useController(
        {name:controlId + ".proposedValue", rules: {...validationRules}});
    const [options, setOptions] = useState(() => {return createInitOptions(property.possibleValues, field.value, property.originalValue, mask)});
    const [commitMutation, isInFlight] = useMutation(propertyMutation);
    
    const handleOnChange = (e) => {
        const val = e == null ? e : e.value
        commitMutation({variables: {
            id: property.id,
                proposedValue: val
            }, onCompleted: () => {
                field.onChange(val)
            }})
    }
    
    const handleOnCreate = (value) => {
        commitMutation({variables: {
            id: property.id,
                proposedValue: value
        }, onCompleted : () => {
                const newOption = {value: value, label: conformValue(value, mask)};
                setOptions([...options,newOption]);
                field.onChange(value);
            }})
    }
    return {field, invalid, error, options, handleOnChange, handleOnCreate, isInFlight, mask}
}
