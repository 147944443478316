/**
 * @generated SignedSource<<cc36d7d300a6652d4521789f49b0f2a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Fragments_AllLanguagesDropdown",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isoCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayOrder",
      "storageKey": null
    }
  ],
  "type": "Language",
  "abstractKey": null
};

node.hash = "acbfd847b01a4b22c4e5fd299263ccf0";

export default node;
