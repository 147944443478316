import {useMemo} from "react";
import {conformToMask} from "react-text-mask";

export const getDecidedFromArrays = (added, changed) => {
    return added.filter(x => x.status !== "Undecided").length + changed.filter(x => x.status !== "Undecided").length
}
export const sortByShowsUp = ((a, b) => {return b.showsUp - a.showsUp})
export const createInitOptions = (possibleValues, currentValue, originalValue, mask) => {
    const options = possibleValues.sort(sortByShowsUp)
        .map((pv) => {
            const label = pv.value === "" ? "<Blank>" : conformValue(pv.value, mask);  
            return {value: pv.value, label:`${label} (shows up: ${pv.showsUp} times)`}})
    
    if(originalValue && !possibleValues.some(o => o.value === originalValue)) {
        options.push({value: originalValue, label: `${conformValue(originalValue, mask)} (Original Value)`});
    }
    
    if(!possibleValues.some(o => o.value === currentValue)) {
        options.unshift({value: currentValue, label: currentValue});
    }
    return options;
}

export const conformValue = (value, translatedMask) => {
    if(translatedMask.length > 0 && value?.length > 0) {
        return conformToMask(value, translatedMask).conformedValue;
    }
    return value;
}

export const translateMask = (mask) => {
    return mask.map(s => {
        return s.type === "String" ? s.value : new RegExp(s.value);
    }) 
}
export const useMemoizedOriginalValue = (properties, propertyName) => {
    const index = useMemo(() => {
        return properties.findIndex((p) => {return p.propertyName === propertyName})
    }, [properties, propertyName]);
    return properties[index].originalValue
}

export const useMemoizedProposedValue = (properties, propertyName) => {
    return useMemo(() => {
        return properties.find(x => x.propertyName === propertyName).proposedValue
    }, [properties, propertyName]);
}