import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allContactPointSystemCodesDropdown, allContactPointUseCodesDropdown} from "../../../relay/Fragments";
import dayjs from "dayjs";
import * as yup from 'yup'
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
const EditLocationContactPointModal = ({showModal, onClose, selectedContactPoint, contactPointSystemCodes, contactPointUseCodes}) => {
    const mutation = graphql`
    mutation EditLocationContactPointModalMutation($id: ID!, $contactPointSystemCodeId: ID!, $contactPointUseCodeId: ID!,
        $value: String!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        location {
            modifyContactPoint(input: {locationContactPointId: $id, contactPointSystemCodeId: $contactPointSystemCodeId,
                contactPointUseCodeId: $contactPointUseCodeId, value: $value, effectiveFrom: $effectiveFrom,
                effectiveTo: $effectiveTo}) {
                locationContactPoint {
                    id
                    location {
                        contactPoints {
                            ...LocationContactPoints_ContactPoints
                        }
                    }
                }
            }
        }
    }`;
    const systemCodes = useFragment(allContactPointSystemCodesDropdown, contactPointSystemCodes).map((cpsc) => {return {value:cpsc.id, label: cpsc.display}});
    const useCodes = useFragment(allContactPointUseCodesDropdown, contactPointUseCodes).map((uc) => {return {value: uc.id, label: uc.display}});
    const [commitMutation, isInFlight] = useMutation(mutation);
    const defaultValues = {
        id: selectedContactPoint.id,
        effectiveFrom: dayjs(selectedContactPoint.effectiveFrom).toDate(),
        effectiveTo: dayjs(selectedContactPoint.effectiveTo).toDate(),
        value: selectedContactPoint.value,
        contactPointSystemCodeId: selectedContactPoint.contactPointSystemCode.id,
        contactPointUseCodeId: selectedContactPoint.contactPointUseCode.id
    }
    const schema = yup.object({
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be after Effective To"),
        effectiveTo:  yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From"),
        value: yup.string().required("Value is required"),
        contactPointSystemCodeId: yup.string().required("System Code is required"),
        contactPointUseCodeId: yup.string().required("Use Code is required")
    })
    const methods = useForm({values: defaultValues, resolver: yupResolver(schema)});
    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
            if(data.location) {
                toast.success("Contact Point has been updated")
                onClose();
            }
            }})
    }
    return (
        <FormModal showModal={showModal} 
                   onClose={onClose} 
                   methods={methods} 
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
                   title={"Edit Contact Point"}
        >
            <HorizontalFormSelect options={systemCodes} controlId={"contactPointSystemCodeId"} label={"System Code"} />
            <HorizontalFormSelect options={useCodes} controlId={"contactPointUseCodeId"} label={"Use Code"} />
            <HorizontalFormInput controlId={"value"} label={"Value"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
EditLocationContactPointModal.propTypes = {}
export default EditLocationContactPointModal