/**
 * @generated SignedSource<<12a1636a7268d9079c79fb48813b02b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactPointSystemCodeId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactPointUseCodeId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locationId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "value"
},
v6 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "contactPointSystemCodeId",
        "variableName": "contactPointSystemCodeId"
      },
      {
        "kind": "Variable",
        "name": "contactPointUseCodeId",
        "variableName": "contactPointUseCodeId"
      },
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "locationId",
        "variableName": "locationId"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "display",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewLocationContactPointModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationMutationGroup",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "LocationContactPointModifyPayload",
            "kind": "LinkedField",
            "name": "createContactPoint",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LocationContactPoint",
                "kind": "LinkedField",
                "name": "locationContactPoint",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Location",
                    "kind": "LinkedField",
                    "name": "location",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LocationContactPoint",
                        "kind": "LinkedField",
                        "name": "contactPoints",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "LocationContactPoints_ContactPoints"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewLocationContactPointModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationMutationGroup",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "LocationContactPointModifyPayload",
            "kind": "LinkedField",
            "name": "createContactPoint",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LocationContactPoint",
                "kind": "LinkedField",
                "name": "locationContactPoint",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Location",
                    "kind": "LinkedField",
                    "name": "location",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LocationContactPoint",
                        "kind": "LinkedField",
                        "name": "contactPoints",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContactPointSystemCode",
                            "kind": "LinkedField",
                            "name": "contactPointSystemCode",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContactPointUseCode",
                            "kind": "LinkedField",
                            "name": "contactPointUseCode",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "053b7836d4506a7c6e89a64243cbfe50",
    "id": null,
    "metadata": {},
    "name": "NewLocationContactPointModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewLocationContactPointModalMutation(\n  $locationId: ID!\n  $value: String!\n  $contactPointSystemCodeId: ID!\n  $contactPointUseCodeId: ID!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  location {\n    createContactPoint(input: {locationId: $locationId, value: $value, contactPointSystemCodeId: $contactPointSystemCodeId, contactPointUseCodeId: $contactPointUseCodeId, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      locationContactPoint {\n        id\n        location {\n          id\n          contactPoints {\n            ...LocationContactPoints_ContactPoints\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment LocationContactPoints_ContactPoints on LocationContactPoint {\n  id\n  contactPointSystemCode {\n    id\n    display\n  }\n  contactPointUseCode {\n    id\n    display\n  }\n  value\n  effectiveFrom\n  effectiveTo\n}\n"
  }
};
})();

node.hash = "511f6a0404a45602c6ed83ceb711ab6c";

export default node;
