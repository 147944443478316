import React from 'react';
import PropTypes from 'prop-types';
import {DateCell} from "../../../components/common/advance-table/CustomCells";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {useUser} from "../../../hooks/useUser";
import {useTableModals} from "../../../hooks/useTableModals";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import NewPractitionerContactPointModal from "./NewPractitionerContactPointModal";
import EditPractitionerContactPointModal from "./EditPractitionerContactPointModal";
import DeleteModal from "../../../components/common/modals/DeleteModal";

const cols = [
    {accessor: 'contactPointSystemCode.display', Header: 'System'},
    {accessor: 'contactPointUseCode.display', Header: "Use"},
    {accessor: 'value', Header: "Value"},
    {
        accessor:"effectiveFrom",
        Header:"Effective From",
        Cell: DateCell
    },
    {
        accessor:"effectiveTo",
        Header:"Effective To",
        Cell:DateCell
    }
]
const PractitionerContactPoints = ({contactPoints, practitionerId, systemCodes, useCodes}) => {
    const deleteMutation = graphql`
    mutation PractitionerContactPointsDeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            owner {
                ... on Practitioner {
                    id
                    contactPoints {
                        id
                    }}}}}`
    const data = useFragment(graphql`
        fragment PractitionerContactPoints_ContactPoints on PractitionerContactPoint @relay(plural: true) {
            id
            contactPointSystemCode {
                display
                id
            }
            contactPointUseCode {
                display
                id
            }
            value
            effectiveFrom
            effectiveTo
        }`, contactPoints)
    const {hasPermission} = useUser();
    const {selected, columns, newModal, editModal, deleteModal} = useTableModals(cols, "ProviderContactPoints.Update", "ProviderContactPoints.Delete");

    return (
        <>
            <AdvanceTableWrapper columns={columns}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={newModal.onClick}
                                         title={"Contact Points"}
                                         table
                                         showNew={hasPermission("ProviderContactPoints.Create")} />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewPractitionerContactPointModal showModal={newModal.showModal}
                                              onClose={newModal.onClose}
                                              systemCodes={systemCodes}
                                              useCodes={useCodes}
                                              practitionerId={practitionerId}
            />
            {selected && <EditPractitionerContactPointModal showModal={editModal.showModal}
                                                            onClose={editModal.onClose}
                                                            systemCodes={systemCodes}
                                                            useCodes={useCodes}
                                                            selectedContactPoint={selected}
            />}
            {selected && <DeleteModal 
                mutation={deleteMutation} 
                onClose={deleteModal.onClose} 
                thingToDelete={"Contact Point"} 
                showModal={deleteModal.showModal}
                id={selected.id}
            >
                <p>This action cannot be undone</p>
            </DeleteModal>}
        </>
    )
}
PractitionerContactPoints.propTypes = {}
export default PractitionerContactPoints