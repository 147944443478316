import {useWatch} from "react-hook-form";
import {sortByShowsUp} from "../helpers";
import {useMemo} from "react";

export const useCurrentValue = (parentKey, propertyName, properties) => {
    const index = useMemo(() => {
        return properties.findIndex((p) => {return p.propertyName === propertyName}
        )},
        [properties, propertyName]);
    const proposedValue = useWatch({name: `${parentKey}.properties[${index}].proposedValue`});
    const mostPopularPossibleValue = properties[index].possibleValues.sort(sortByShowsUp)[0].value;
    return proposedValue ? proposedValue : mostPopularPossibleValue;
}