import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from "react-bootstrap";
import IconButton from "../IconButton";
import AdvanceTableSearchBox from "../advance-table/AdvanceTableSearchBox";

const IndexTableHeader = ({globalFilter, setGlobalFilter, name, onNewClick, showNew, showFilters, onFiltersClick}) => {
    return (
        <Row className={"flex-between-center "}>
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h1 className="h4 fs-0 mb-0 me-3 text-nowrap py-2 py-xl-0">{name}</h1>
                {showNew &&
                <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    onClick={onNewClick}
                >
                    <span className="d-none d-sm-inline-block ms-1">New</span>
                </IconButton>}
            </Col>
            <Col xs={8} sm="auto" className="d-flex align-items-center ms-auto text-end ps-0">
                {showFilters && <Button className="me-2" variant={"falcon-default"} onClick={onFiltersClick} size={"sm"}> Filters</Button>}
                <search title={name}>
                    <AdvanceTableSearchBox globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                </search>
            </Col>
        </Row>
    )
}
IndexTableHeader.defaultProps = {
    showNew: true,
    showFilters: false
}
IndexTableHeader.propTypes = {
    name: PropTypes.string.isRequired,
    onNewClick: PropTypes.func.isRequired,
    showNew: PropTypes.bool,
    showFilters: PropTypes.bool,
    onFiltersClick: PropTypes.func
}
export default IndexTableHeader