import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allContactPointSystemCodesDropdown, allContactPointUseCodesDropdown} from "../../../relay/Fragments";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";

const NewLocationContactPointModal = ({showModal, onClose, locationId, contactPointSystemCodes, contactPointUseCodes}) => {
    const mutation = graphql`
    mutation NewLocationContactPointModalMutation($locationId: ID!, $value: String!, $contactPointSystemCodeId: ID!, $contactPointUseCodeId: ID!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        location {
            createContactPoint(input: {locationId: $locationId, value: $value, contactPointSystemCodeId: $contactPointSystemCodeId, contactPointUseCodeId: $contactPointUseCodeId, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                locationContactPoint {
                    id
                    location {
                        id
                        contactPoints {
                            ...LocationContactPoints_ContactPoints
                        }
                    }
                }
            }
        }
    }`
    const systemCodes = useFragment(allContactPointSystemCodesDropdown, contactPointSystemCodes).map((cpsc) => {return {value:cpsc.id, label: cpsc.display}});
    const useCodes = useFragment(allContactPointUseCodesDropdown, contactPointUseCodes).map((uc) => {return {value: uc.id, label: uc.display}});
    
    const [commitMutation, isInFlight] = useMutation(mutation);
    const defaultValues = {
        locationId: locationId,
        value: '',
        contactPointSystemCodeId: '',
        contactPointUseCodeId: '',
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31)
    }
    const schema = yup.object({
        value: yup.string().required("Value is required"),
        contactPointSystemCodeId: yup.string().required("System Code is required"),
        contactPointUseCodeId: yup.string().required("Use Code is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
        
    })
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
            if(data.location) {
                toast.success("Contact Point for Location created");
                methods.reset();
                onClose();
            }
            }})
    }
    return (
        <FormModal showModal={showModal} 
                   onClose={onClose} 
                   isInFlight={isInFlight} 
                   methods={methods}
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormSelect options={systemCodes} controlId={"contactPointSystemCodeId"} label={"System Code"} />
            <HorizontalFormSelect options={useCodes} controlId={"contactPointUseCodeId"} label={"Use Code"} />
            <HorizontalFormInput controlId={"value"} label={"Value"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
NewLocationContactPointModal.defaultProps = {}
NewLocationContactPointModal.propTypes = {}
export default NewLocationContactPointModal