/**
 * @generated SignedSource<<0f3d1260606c837752120173c343c8aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Fragments_AllProfessionalDesignationDropdown",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayOrder",
      "storageKey": null
    }
  ],
  "type": "ProfessionalDesignation",
  "abstractKey": null
};

node.hash = "159b576a1d363f20b8046f6e52f6f84b";

export default node;
