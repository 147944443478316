/**
 * @generated SignedSource<<ce92ddd2e8e0954f82f907d8e174fd86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactPointSystemId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactPointUseId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "practitionerId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "value"
},
v6 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "contactPointSystemId",
        "variableName": "contactPointSystemId"
      },
      {
        "kind": "Variable",
        "name": "contactPointUseId",
        "variableName": "contactPointUseId"
      },
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "practitionerId",
        "variableName": "practitionerId"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "display",
    "storageKey": null
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewPractitionerContactPointModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerMutationGroup",
        "kind": "LinkedField",
        "name": "practitioner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "PractitionerContactPointModifyPayload",
            "kind": "LinkedField",
            "name": "createContactPoint",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerContactPoint",
                "kind": "LinkedField",
                "name": "practitionerContactPoint",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Practitioner",
                    "kind": "LinkedField",
                    "name": "practitioner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerContactPoint",
                        "kind": "LinkedField",
                        "name": "contactPoints",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PractitionerContactPoints_ContactPoints"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewPractitionerContactPointModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerMutationGroup",
        "kind": "LinkedField",
        "name": "practitioner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "PractitionerContactPointModifyPayload",
            "kind": "LinkedField",
            "name": "createContactPoint",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerContactPoint",
                "kind": "LinkedField",
                "name": "practitionerContactPoint",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Practitioner",
                    "kind": "LinkedField",
                    "name": "practitioner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerContactPoint",
                        "kind": "LinkedField",
                        "name": "contactPoints",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContactPointSystemCode",
                            "kind": "LinkedField",
                            "name": "contactPointSystemCode",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContactPointUseCode",
                            "kind": "LinkedField",
                            "name": "contactPointUseCode",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab4d40f568d45c1dcb6cfb2b2a80fb85",
    "id": null,
    "metadata": {},
    "name": "NewPractitionerContactPointModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewPractitionerContactPointModalMutation(\n  $practitionerId: ID!\n  $contactPointSystemId: ID!\n  $contactPointUseId: ID!\n  $value: String!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  practitioner {\n    createContactPoint(input: {practitionerId: $practitionerId, contactPointSystemId: $contactPointSystemId, contactPointUseId: $contactPointUseId, value: $value, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      practitionerContactPoint {\n        id\n        practitioner {\n          contactPoints {\n            ...PractitionerContactPoints_ContactPoints\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment PractitionerContactPoints_ContactPoints on PractitionerContactPoint {\n  id\n  contactPointSystemCode {\n    display\n    id\n  }\n  contactPointUseCode {\n    display\n    id\n  }\n  value\n  effectiveFrom\n  effectiveTo\n}\n"
  }
};
})();

node.hash = "b7eca450e29f71c2c404cfddedc443c2";

export default node;
