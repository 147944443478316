import React from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Row} from "react-bootstrap";
import IconButton from "../IconButton";

const InfoPanel = (props) => {
    return (
        <Card className="mb-3">
            <Card.Header>
                <Row className={"align-items-center"}>
                    <Col>
                        <h2 className={"h5 mb-0"}>Info for {props.name}</h2>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className={"bg-light border-top"}>
                {props.children}
            </Card.Body>
            <Card.Footer className={"border-top text-end"}>
                <Row className={"align-items-end"}>
                    <Col>
                        {props.readMode && props.showUpdate && <IconButton
                            iconClassName="fs--2 me-1"
                            variant="falcon-default"
                            size="sm"
                            icon="pencil-alt"
                            onClick={props.onUpdateDetailsClick}
                            >
                            Update Details 
                        </IconButton>}
                        {!props.readMode && <IconButton
                            iconClassName="fs--2 me-1"
                            variant="falcon-default"
                            size="sm"
                            icon={"trash-alt"}
                            onClick={props.onCancelChangesClick}
                            disabled={props.isInFlight}
                        >
                            Cancel changes
                        </IconButton>}
                        {!props.readMode &&
                            <IconButton
                                className="ms-2"
                                iconClassName="fs--2 me-1"
                                variant="falcon-default"
                                size="sm"
                                icon="check"
                                disabled={props.isInFlight}
                                type={"submit"}
                            >
                                {props.isInFlight ? "Saving changes" : "Save changes"}
                            </IconButton>}
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    )
}
InfoPanel.defaultProps = {
    showUpdate: true
}
InfoPanel.propTypes = {
    name: PropTypes.string.isRequired,
    readMode: PropTypes.bool.isRequired,
    isInFlight: PropTypes.bool.isRequired,
    onCancelChangesClick: PropTypes.func.isRequired,
    onUpdateDetailsClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    showUpdate: PropTypes.bool
}
export default InfoPanel