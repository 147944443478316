/**
 * @generated SignedSource<<28f73abe69e3aba5b1282c2051401b3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOrder",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "proposedValue",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "propertyName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "RosterLoadPropertyMaskElement",
  "kind": "LinkedField",
  "name": "mask",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "RosterLoadProperty",
  "kind": "LinkedField",
  "name": "keys",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "RosterLoadProperty",
  "kind": "LinkedField",
  "name": "properties",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v5/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalValue",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "required",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isKey",
      "storageKey": null
    },
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pattern",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patternErrorMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PossibleValue",
      "kind": "LinkedField",
      "name": "possibleValues",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showsUp",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/)
],
v11 = [
  (v0/*: any*/)
],
v12 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "RosterLoadComponent",
    "kind": "LinkedField",
    "name": "parentLocation",
    "plural": false,
    "selections": (v11/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "RosterLoadComponent",
    "kind": "LinkedField",
    "name": "parentPractitioner",
    "plural": false,
    "selections": (v11/*: any*/),
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InProgressRosterLoadFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fileName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RosterLoadLegalContractLineOfService",
      "kind": "LinkedField",
      "name": "legalContractLinesOfService",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LegalContractLineOfService",
          "kind": "LinkedField",
          "name": "linesOfService",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LineOfService",
              "kind": "LinkedField",
              "name": "lineOfService",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LegalContract",
              "kind": "LinkedField",
              "name": "contract",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RosterLoadComponent",
      "kind": "LinkedField",
      "name": "addedBillingGroups",
      "plural": true,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RosterLoadComponent",
      "kind": "LinkedField",
      "name": "changedBillingGroups",
      "plural": true,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RosterLoadComponent",
      "kind": "LinkedField",
      "name": "addedLocations",
      "plural": true,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RosterLoadComponent",
      "kind": "LinkedField",
      "name": "changedLocations",
      "plural": true,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RosterLoadComponent",
      "kind": "LinkedField",
      "name": "addedPractitioners",
      "plural": true,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RosterLoadComponent",
      "kind": "LinkedField",
      "name": "changedPractitioners",
      "plural": true,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RosterLoadComponent",
      "kind": "LinkedField",
      "name": "addedPractitionerLocations",
      "plural": true,
      "selections": (v12/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RosterLoadComponent",
      "kind": "LinkedField",
      "name": "changedPractitionerLocations",
      "plural": true,
      "selections": (v12/*: any*/),
      "storageKey": null
    }
  ],
  "type": "RosterLoad",
  "abstractKey": null
};
})();

node.hash = "f5ca0d5ef1c5424289ac55ecf2f66b56";

export default node;
