import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Card, CardGroup, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {useMemoizedProposedValue} from "../helpers";
import {FormatAddress} from "../../../helpers/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PractitionerLocationKeySection = ({keys, practitionerName, locationName}) => {
    const npi = useMemoizedProposedValue(keys, "NPI");
    const address = useMemo(() => {
        const address1 = keys.find((p) => p.propertyName === "Address 1")?.proposedValue;
        const address2 = keys.find((p) => p.propertyName === "Address 2")?.proposedValue;
        const city = keys.find((p) => p.propertyName === "City")?.proposedValue;
        const state = keys.find((p) => p.propertyName === "State")?.proposedValue;
        const zip = keys.find((p) => p.propertyName === "Zip")?.proposedValue;
        return FormatAddress(address1, address2, "",city,state,zip);
    }, [keys])
    return (
        <Row className={"mb-2"}>
            <Col>
                <Card className={"bg-200"}>
                    <Card.Header>
                        <h3 className={"h6 mb-0 mt-0"}>Keys
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip style={{ position: 'fixed' }}>
                                        Provider Location uses a combination of the keys for Provider and Location to create a unique instance.
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={['far', 'question-circle']}
                                        transform="shrink-1"
                                        className="ms-1 text-400"
                                    />
                                </span>
                            </OverlayTrigger>
                        </h3>
                    </Card.Header>
                    <Card.Body className={"pt-0"}>
                        <Row md={2}>
                            <Col md={4}>
                            <Card>
                                <Card.Body>
                                <Card.Title><h4 className={"h5"}>Provider</h4></Card.Title>
                                <dl>
                                    <Row>
                                        <Col md={3}><dt>Name</dt></Col>
                                        <Col><dd>{practitionerName}</dd></Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}><dt>Npi</dt></Col>
                                        <Col><dd>{npi}</dd></Col>
                                    </Row>
                                </dl>
                                </Card.Body>
                            </Card>
                            </Col>
                            <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title><h4 className={"h5 mb-0 mt-0"}>Location</h4></Card.Title>
                                    <dl>
                                        <Row>
                                            <Col md={2}><dt>Name</dt></Col>
                                            <Col><dd>{locationName}</dd></Col>
                                        </Row>
                                        <Row>
                                            <Col md={2}><dt>Address</dt></Col>
                                            <Col><dd>{address}</dd></Col>
                                        </Row>
                                    </dl>
                                </Card.Body>
                            </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}
PractitionerLocationKeySection.defaultProps = {}
PractitionerLocationKeySection.propTypes = {}
export default PractitionerLocationKeySection