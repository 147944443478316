import React from 'react';
import PropTypes from "prop-types";
import ChangeAccordionItem from "../components/ChangeAccordionItem";
import {useMemoizedOriginalValue, useMemoizedProposedValue} from "../helpers";
import KeySection from "../components/KeySection";

const PractitionerChange = ({practitionerChanged, practitionerIndex}) => {
    const key = "changedPractitioners[" + practitionerIndex + "]"
    const firstName = useMemoizedOriginalValue(practitionerChanged.properties, "First Name");
    const lastName = useMemoizedOriginalValue(practitionerChanged.properties, "Last Name");
    const npi = useMemoizedProposedValue(practitionerChanged.keys, "NPI");
    const effectiveDate = useMemoizedProposedValue(practitionerChanged.keys, "Effective Date")
    return (
        <ChangeAccordionItem properties={practitionerChanged.properties}
                             controlId={key}
                             effectiveDate={effectiveDate}
                             header={firstName + " " + lastName + " : " + npi}
                             index={practitionerIndex}
        >
            <KeySection keys={practitionerChanged.keys}
                        tooltip={"The keys are used to identify a unique practitioner in a file and match it to what's already in Trellis"}
            />
        </ChangeAccordionItem>
    )
}
PractitionerChange.propTypes = {
    practitionerChanged: PropTypes.object.isRequired,
    practitionerIndex: PropTypes.number.isRequired
}
export default PractitionerChange