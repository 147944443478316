import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import ChangeAccordionItem from "../components/ChangeAccordionItem";
import {useMemoizedOriginalValue} from "../helpers";
import {createProviderFullName} from "../../../helpers/utils";
import PractitionerLocationKeySection from "./PractitionerLocationKeySection";

const PractitionerLocationChange = ({practitionerLocationChanged, practitionerLocationIndex, practitioner, location}) => {
    const key = "changedPractitionerLocations[" + practitionerLocationIndex + "]";
    const practitionerName = useMemo(() => {
        const firstName = practitioner.properties.find((p) => p.propertyName === "First Name")?.proposedValue;
        const lastName = practitioner.properties.find((p) => p.propertyName === "Last Name")?.proposedValue;
        const middleInitial = practitioner.properties.find((p) => p.propertyName === "Middle Initial")?.proposedValue;
        const nameSuffix = practitioner.properties.find((p) => p.propertyName === "Name Suffix")?.proposedValue
        return  createProviderFullName(firstName, lastName, middleInitial, nameSuffix);
    }, [practitioner])
    const locationName = useMemoizedOriginalValue(location.properties, "Name");
    return (
        <ChangeAccordionItem properties={practitionerLocationChanged.properties}
                             controlId={key}
                             header={practitionerName + " at " + locationName}
                             index={practitionerLocationIndex}
        >
            <PractitionerLocationKeySection practitionerName={practitionerName} 
                                            locationName={locationName} 
                                            keys={practitionerLocationChanged.keys} 
            />
        </ChangeAccordionItem>
    )
}
PractitionerLocationChange.propTypes = {
    practitionerLocationChanged: PropTypes.object.isRequired,
    practitionerLocationIndex: PropTypes.number.isRequired
}
export default PractitionerLocationChange