import React from 'react';
import {Accordion, Card} from "react-bootstrap";
import BillingGroupAdd from "./BillingGroupAdd";
import BillingGroupChange from "./BillingGroupChange";
import {useFieldArray} from "react-hook-form";

const BillingGroupTab = (props) => {
    const {fields} = useFieldArray({name: "addedBillingGroups"});
    const {fields: changedFields} = useFieldArray({name: "changedBillingGroups"});
    return (
        <Card>
            <Card.Body className={"bg-light"}>
                {fields && fields.length > 0 && 
                    <Accordion defaultActiveKey={fields.some(s => s.status === "Undecided") ? fields.findIndex(s => s.status === "Undecided").toString() : "0"} className={"mb-4"}>
                        {fields.map((field, index) => (
                            <BillingGroupAdd billingGroupIndex={index} 
                                             billingGroupAdded={field} 
                                             key={field.id}
                            />
                        ))}
                    </Accordion>
                }
                {changedFields && changedFields.length > 0 && 
                    <Accordion defaultActiveKey={fields.some(s => s.status === "Undecided") ? changedFields.findIndex(s => s.status === "Undecided").toString() : "0"} className={"mb-4"}>
                        {changedFields.map((field, index) => {
                        return <BillingGroupChange billingGroupChanged={field} 
                                                   billingGroupIndex={index} 
                                                   key={field.id}
                        />
                })}
                    </Accordion>
                }
            </Card.Body>
        </Card>
    )
}
export default BillingGroupTab