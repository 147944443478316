import React from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

const DetailsPageHeader = ({title, iconText, iconType}) => {
    const navigate = useNavigate();
    return (
        <Card className={"mb-3"}>
            <Card.Header>
                <Row>
                    <a onClick={() => {navigate(-1)}} title={"Go back to the previous page"} className={"h6 link-secondary text-600 cursor-pointer"}>
                        <FontAwesomeIcon icon={"chevron-left"} aria-hidden={true} className={"me-2"} />
                        BACK
                    </a>
                </Row>
                <Row as={'hgroup'}>
                    <Col>
                        <h1 className={"mb-2 h5"}>{title}</h1>
                    </Col>
                    <Col xs={"auto"}>
                        <p className={"h6 text-uppercase text-600"}>
                            {iconText}
                            <FontAwesomeIcon icon={iconType} className={"ms-2"} />
                        </p>
                    </Col>
                </Row>
            </Card.Header>
        </Card>
    )
}
DetailsPageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    iconText: PropTypes.string.isRequired,
    iconType: PropTypes.string.isRequired
}
export default DetailsPageHeader