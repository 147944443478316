import React, {useContext, useEffect, useMemo} from 'react';
import {UserContext} from "../context/Context";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import {useLocalStorage} from "./useLocalStorage";
import {removeItemFromStore} from "../helpers/utils";

const UserProvider = ({children, userData}) => {
    const [user, setUser] = useLocalStorage("user", userData);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener("NMS:Unauthorized", handleUnauthorizedEvent);
        return () => {
            window.removeEventListener('NMS:Unauthorized', handleUnauthorizedEvent);
        }
    }, []);
    
    const handleUnauthorizedEvent = () => {
        removeItemFromStore("user");
        setUser(null);
    }
    const hasPermission = (permission) => {
        if(!user || !user.permissions) {
            return false;
        }
        return user.permissions.includes(permission)
    }
    const login = async (username, password) => {
       let response = await fetch("/account/login", {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        })
        if(response.ok) {
            const userData = await response.json();
            setUser(userData);
            const from = location.pathname || "/";
            navigate(from, {replace: true});
        }
        else {
            toast.error("Login failed. Check your username and password")
            return null;
        }
    }
    const logout = async () => {
        let response = await fetch("/account/logout", {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
            }
        })
        if(response.ok) {
            setUser(null);
            return null;
        }
        else {
            toast.error("Logout failed.")
            return null;
        }
    }
    const value = useMemo(() => ({user, hasPermission, login, logout}),[user]);
    return <UserContext.Provider value={value}>
        {children}
    </UserContext.Provider>
}
const useUser = () => {
    const context = useContext(UserContext)
    if(context === undefined) {
        throw new Error('useUser must be used within an UserProvider')
    }
    return context;
}

export {useUser, UserProvider}
