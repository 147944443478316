import React from 'react';
import {useFormState, useWatch} from "react-hook-form";
import {Nav} from "react-bootstrap";
import SoftBadge from "../../../components/common/SoftBadge";
import {getDecidedFromArrays} from "../helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TabBadge = ({isInvalid, addedItems, changedItems}) => {
    const countDecided = getDecidedFromArrays(addedItems, changedItems);
    const totalCount = addedItems.length + changedItems.length;
    return (
        <SoftBadge pill bg={isInvalid ? "danger" : "primary"}>
            {isInvalid && <FontAwesomeIcon icon={"exclamation-triangle"} size={"lg"} title={"Error in tab"} />}
            {countDecided}/{totalCount}
        </SoftBadge>)
}

const TabNav = () => {
    const [addedBillingGroups, changedBillingGroups, addedLocations, changedLocations, addedPractitioners, changedPractitioners, addedPractitionerLocations, changedPractitionerLocations] = useWatch({name: ["addedBillingGroups", "changedBillingGroups", "addedLocations", "changedLocations", "addedPractitioners", "changedPractitioners", "addedPractitionerLocations", "changedPractitionerLocations"]});
    const {errors} = useFormState();
    return (
        <Nav variant={"tabs"}>
            <Nav.Item><Nav.Link eventKey={"rosterLoadDetails"}>Roster Load Details</Nav.Link></Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey={"billingGroups"}>Billing Groups <TabBadge isInvalid={!!errors?.node?.addedBillingGroups} addedItems={addedBillingGroups} changedItems={changedBillingGroups}/></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey={"providers"}>Providers <TabBadge isInvalid={!!errors?.node?.addedPractitioners} addedItems={addedPractitioners} changedItems={changedPractitioners}/></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey={"locations"}>Practice Locations <TabBadge isInvalid={!!errors?.node?.addedLocations} addedItems={addedLocations} changedItems={changedLocations}/></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey={"providerLocations"}>Provider Locations <TabBadge isInvalid={!!errors?.node?.addedPractitionerLocations} addedItems={addedPractitionerLocations} changedItems={changedPractitionerLocations}/></Nav.Link>
            </Nav.Item>
        </Nav>
    )
}
export default TabNav
