import React from 'react';
import {Card} from "react-bootstrap";

const CompleteRosterLoad = (props) => {
    return (
        <Card>
            <Card.Body className={"bg-light"}>
                This roster load was completed.
            </Card.Body>
        </Card>
    )
}
export default CompleteRosterLoad