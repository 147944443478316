import React from 'react';
import PropTypes from 'prop-types';
import ChangeAccordionItem from "../components/ChangeAccordionItem";
import {useMemoizedOriginalValue, useMemoizedProposedValue} from "../helpers";
import KeySection from "../components/KeySection";

const LocationChange = ({locationChanged, locationIndex}) => {
    const key = "changedLocations[" + locationIndex + "]"
    const name = useMemoizedOriginalValue(locationChanged.properties, "Name");
    const effectiveDate = useMemoizedProposedValue(locationChanged.keys, "Effective Date");
    return (
        <ChangeAccordionItem properties={locationChanged.properties}
                             controlId={key}
                             effectiveDate={effectiveDate}
                             header={name}
                             index={locationIndex}
        >
            <KeySection keys={locationChanged.keys} tooltip={"The keys are used to identify a unique location in a file and match it to what's already in Trellis"} />
        </ChangeAccordionItem>
    )
}
LocationChange.propTypes = {
    locationChange : PropTypes.object.isRequired,
    locationIndex: PropTypes.number.isRequired
}
export default LocationChange