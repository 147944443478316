import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allContactPointSystemCodesDropdown, allContactPointUseCodesDropdown} from "../../../relay/Fragments";
import dayjs from "dayjs";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";

const EditPractitionerContactPointModal = ({showModal, onClose, selectedContactPoint, systemCodes, useCodes}) => {
    const mutation = graphql`
    mutation EditPractitionerContactPointModalMutation($practitionerContactPointId: ID!, $contactPointSystemId:ID!, $contactPointUseId: ID!,
    $value: String!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        practitioner {
            modifyContactPoint(input: {practitionerContactPointId: $practitionerContactPointId, contactPointSystemId: $contactPointSystemId,
            contactPointUseId: $contactPointUseId, value: $value, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                practitionerContactPoint {
                    id
                    practitioner {
                        contactPoints {
                            ...PractitionerContactPoints_ContactPoints
                        }}}}}}`
    const contactPointSystem = useFragment(allContactPointSystemCodesDropdown, systemCodes).map((cpsc) => {return {value:cpsc.id, label: cpsc.display}});
    const contactPointUse = useFragment(allContactPointUseCodesDropdown, useCodes).map((uc) => {return {value: uc.id, label: uc.display}});
    const [commitMutation, isInFlight] = useMutation(mutation);
    const effectiveFrom = selectedContactPoint?.effectiveFrom ? dayjs(selectedContactPoint?.effectiveFrom).toDate() : '';
    const effectiveTo = selectedContactPoint?.effectiveTo ? dayjs(selectedContactPoint?.effectiveTo).toDate() : '';
    const defaultValues = {
        practitionerContactPointId: selectedContactPoint?.id,
        contactPointUseId: selectedContactPoint.contactPointUseCode.id,
        contactPointSystemId:selectedContactPoint.contactPointSystemCode.id,
        value: selectedContactPoint.value,
        effectiveFrom: effectiveFrom,
        effectiveTo: effectiveTo
    }
    const schema = yup.object({
        contactPointSystemId: yup.string().required("System Code is required"),
        contactPointUseId: yup.string().required("Use Code is required"),
        value: yup.string().required("Value is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    })
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});

    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
                if(data.practitioner) {
                    toast.success("Contact Point for Provider updated")
                    methods.reset();
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal} 
                   onClose={onClose}
                   title={"Update Contact Point"}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit} 
                   methods={methods}
        >
            <HorizontalFormSelect options={contactPointSystem} controlId={"contactPointSystemId"} label={"System Code"} />
            <HorizontalFormSelect options={contactPointUse} controlId={"contactPointUseId"} label={"Use Code"} />
            <HorizontalFormInput controlId={"value"} label={"Value"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
EditPractitionerContactPointModal.propTypes = {}
export default EditPractitionerContactPointModal