import React from 'react';
import PropTypes from 'prop-types';
import AddAccordionItem from "../components/AddAccordionItem";
import {useCurrentValue} from "../components/useCurrentValue";
import {useMemoizedProposedValue} from "../helpers";
import KeySection from "../components/KeySection";

const LocationAdd = ({locationAdded, locationIndex}) => {
    const key = `addedLocations[${locationIndex}]`;
    const header = useCurrentValue(key, "Name", locationAdded.properties);
    const effectiveDate = useMemoizedProposedValue(locationAdded.keys, "Effective Date");
    return (
        <AddAccordionItem controlId={key}
                          index={locationIndex}
                          header={header}
                          effectiveDate={effectiveDate}
                          properties={locationAdded.properties}
        >
            <KeySection keys={locationAdded.keys} tooltip={"The keys are used to identify unique locations in the file"} />
        </AddAccordionItem>
    )
}
LocationAdd.propTypes = {
    locationAdded : PropTypes.object.isRequired,
    locationIndex: PropTypes.number.isRequired
}
export default LocationAdd