import React from 'react';
import PropTypes from 'prop-types';
import AddAccordionItem from "../components/AddAccordionItem";
import {useCurrentValue} from "../components/useCurrentValue";
import {useMemoizedProposedValue} from "../helpers";
import KeySection from "../components/KeySection";


const BillingGroupAdd = ({billingGroupAdded, billingGroupIndex}) => {
    const billingGroupKey = "addedBillingGroups[" + billingGroupIndex + "]"
    const name = useCurrentValue(billingGroupKey,"Name", billingGroupAdded.properties);
    const npi = useMemoizedProposedValue(billingGroupAdded.keys, "NPI");
    const effectiveDate = useMemoizedProposedValue(billingGroupAdded.keys, "Effective Date");
    return (
            <AddAccordionItem properties={billingGroupAdded.properties} 
                              header={name + " : " + npi}
                              controlId={billingGroupKey}
                              index={billingGroupIndex}
                              effectiveDate={effectiveDate}
            >
                <KeySection keys={billingGroupAdded.keys} tooltip={"The keys are used to identify unique billing groups in the file"} />
            </AddAccordionItem>
    )
}
BillingGroupAdd.propTypes = {
    billingGroupAdded: PropTypes.object.isRequired,
    billingGroupIndex: PropTypes.number.isRequired
}
export default BillingGroupAdd