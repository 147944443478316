import React from 'react';
import {useLoaderData} from "react-router-dom";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import AnalyzingRosterLoad from "./AnalyzingRosterLoad";
import InProgressRosterLoad from "./InProgressRosterLoad";
import CompleteRosterLoad from "./CompleteRosterLoad";
import FailedRosterLoad from "./FailedRosterLoad";

const RosterLoadDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(
        graphql`query RosterLoadDetailsQuery($id: ID!) {
            node(id: $id) {
                ... on RosterLoad {
                    id
                    status
                    errorMessage
                    errorDetails
                    fileName
                    ...InProgressRosterLoadFragment
                }
            }
        }`
    ,loader.rosterLoadDetailsQuery);
    return (
        <>
            <DetailsPageHeader title={"Roster Load: " + data.node.fileName} iconText={"Roster Load"} iconType={"upload"} />
            {data.node.status === "Analyzing" && <AnalyzingRosterLoad />}
            {data.node.status === "Failed" && <FailedRosterLoad rosterLoad={data.node} />}
            {data.node.status === "InProgress" && <InProgressRosterLoad rosterLoad={data.node} />}
            {data.node.status === "Complete" && <CompleteRosterLoad />}
        </>
    )
}
export default RosterLoadDetails
