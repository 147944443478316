import React, {useMemo, useState} from "react";
import {useUser} from "./useUser";
import CardDropdown from "../components/common/CardDropdown";
import DropdownItem from "react-bootstrap/DropdownItem";

export const useTableModals = (columns, editPermission, deletePermission) => {
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const {hasPermission} = useUser();
    const handleEditClick = (data) => {
        console.log(data);
        setSelected(data);
        setShowEditModal(true);
    }
    const handleDeleteClick = (data) => {
        setSelected(data);
        setShowDeleteModal(true);
    }
    const cols = useMemo(() => {
        const canEdit = hasPermission(editPermission);
        const canDelete = hasPermission(deletePermission);
        const col = {
            accessor: 'none',
            Headers: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            }
        }
        if(canEdit && canDelete) {
            return columns.concat([{
                ...col,
                Cell: (rowData) => {
                    return <CardDropdown>
                        <DropdownItem as={"button"} onClick={() => {handleEditClick(rowData.row.original)}}>Edit</DropdownItem>
                        <DropdownItem as={"button"} onClick={() => {handleDeleteClick(rowData.row.original)}}>Delete</DropdownItem>
                    </CardDropdown>
                }
            }])
        }
        if(canEdit) {
            return columns.concat([{
                ...col,
                Cell: (rowData) => {
                    return <CardDropdown>
                        <DropdownItem as={"button"} onClick={() => {handleEditClick(rowData.row.original)}}>Edit</DropdownItem>
                    </CardDropdown>
                }
            }])
        }
        if(canDelete) {
            return columns.concat([{
                ...col,
                Cell: (rowData) => {
                    return <CardDropdown>
                        <DropdownItem as={"button"} onClick={() => {handleDeleteClick(rowData.row.original)}}>Delete</DropdownItem>
                    </CardDropdown>
                }
            }])
        }
        return columns;
    },[hasPermission, editPermission, deletePermission, columns])
    
    const handleNewClick = () => {
        setShowNewModal(true);
    }
    const onNewClose = () => {
        setShowNewModal(false);
    }
    const onEditClose = () => {
        setShowEditModal(false);
    }
    const onDeleteClose = () => {
        setShowDeleteModal(false);
    }
    return {selected,
        columns: cols,    
        newModal: {
            showModal:showNewModal,
            onClick: () => {setShowNewModal(true)},
            onClose: () => {setShowNewModal(false)}
            },
        editModal: {
            showModal: showEditModal,
            onClose: () => {setShowEditModal(false)}
        },
        deleteModal: {
            showModal: showDeleteModal,
            onClose: () => {setShowDeleteModal(false)}
        }
    }
}