import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {addEditAndDeleteCells, DateCell} from "../../../components/common/advance-table/CustomCells";
import {useFragment} from "react-relay";
import {useUser} from "../../../hooks/useUser";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import NewPractitionerLanguageModal from "./NewPractitionerLanguageModal";
import EditPractitionerLanguageModal from "./EditPractitionerLanguageModal";
import DeleteModal from "../../../components/common/modals/DeleteModal";

const columns = [
    {accessor: 'language.name', Header: 'Language'},
    {accessor: 'seq', Header: 'Order'},
    {accessor: 'effectiveFrom', Header: "Effective From", Cell: DateCell},
    {accessor:'effectiveTo', Header:'Effective To', Cell: DateCell}
]
const PractitionerLanguages = ({practitionerId, languages, allLanguages}) => {
    const deleteMutation = graphql`
    mutation PractitionerLanguagesDeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            owner {
                ... on Practitioner {
                    id
                    languages {
                        id
                    }}}}}`
    const data = useFragment(graphql`
        fragment PractitionerLanguages_Languages on PractitionerLanguage @relay(plural:true) {
            id
            language {
                id
                name
                isoCode
                displayOrder
            }
            seq
            effectiveFrom
            effectiveTo
        }
    `,languages)
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const handleEditClick = (data) => {
        setSelectedLanguage(data);
        setShowEditModal(true);
    }
    const handleDeleteClick = (data) => {
        setSelectedLanguage(data);
        setShowDeleteModal(true);
    }
    const cols = useMemo(() => {
        const canEdit = hasPermission("Providers.Update");
        const canDelete = hasPermission("Providers.Delete");
        return addEditAndDeleteCells(canEdit, canDelete, columns, handleEditClick, handleDeleteClick)
    }, [hasPermission])
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                         title={"Languages"}
                                         table
                                         showNew={hasPermission("Providers.Update")} />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewPractitionerLanguageModal showModal={showNewModal}
                                          onClose={() => {setShowNewModal(false);}}
                                          languages={allLanguages}
                                          practitionerId={practitionerId}
            />
            {selectedLanguage && <EditPractitionerLanguageModal showModal={showEditModal}
                                                                onClose={() => {setShowEditModal(false)}}
                                                                selectedLanguage={selectedLanguage}
                                                                languages={allLanguages}
            />}
            {selectedLanguage && <DeleteModal mutation={deleteMutation} 
                                              onClose={() => {setShowDeleteModal(false)}} 
                                              thingToDelete={"Language"} 
                                              showModal={showDeleteModal}
                                              id={selectedLanguage.id}
            >
                <p>This cannot be undone.</p>
            </DeleteModal>}
        </>
    )
}
PractitionerLanguages.propTypes = {
    practitionerId: PropTypes.string.isRequired,
    allLanguages: PropTypes.array.isRequired,
    languages: PropTypes.array.isRequired
}
export default PractitionerLanguages