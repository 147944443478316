import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useController} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import DropdownIndicator from "./DropdownIndicator";
import AsyncSelect from "react-select/async";
import classNames from "classnames";

const HorizontalFormAsyncSelect = ({controlId, label, readMode, loadOptions, defaultOptions, validation, ...rest}) => {
    const {field, fieldState: {invalid, error}, formState: {defaultValues}} = useController({name: controlId})
    const findInitValue = () => {
        if(defaultOptions === true) {
            return {}
        }
        if(field.value) {
            return defaultOptions.find(o => o.value === field.value)
        }
        if(defaultValues[controlId]) {
            return defaultOptions.find(o => o.value === defaultValues[controlId]);
        }
        return {}
    }
    const [controlValue, setControlValue] = useState(findInitValue())
    return (
        <Form.Group as={Row} className={"mb-1"} controlId={controlId}>
            <Form.Label column xs={7} sm={3}>{label}</Form.Label>
            <Col>
                {readMode ? <Form.Control value={controlValue.label}
                                          size={"sm"}
                                          disabled
                                          plaintext
                    />
                    :
                    <AsyncSelect placeHolder={"Select..."}
                                 components={{DropdownIndicator}}
                                 loadOptions={loadOptions}
                                 classNamePrefix={"react-select"}
                                 className={classNames({'is-invalid': invalid})}
                                 styles={{
                                     control: (baseStyles) => ({
                                         ...baseStyles,
                                         borderColor: invalid ? "#e63757!important" : 'var(--falcon-input-border-color)'
                                     })
                                 }}
                                 inputId={controlId}
                                 isSearchable
                                 value={controlValue}
                                 onChange={(e) => {
                                     setControlValue(e);
                                     field.onChange(e.value);
                                 }}
                                 {...rest}
                    />}
            </Col>
        </Form.Group>
    )
}
HorizontalFormAsyncSelect.defaultProps = {
    readMode: false
}
HorizontalFormAsyncSelect.propTypes = {
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    loadOptions: PropTypes.func.isRequired,
    defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired
    }))]).isRequired,
    readMode: PropTypes.bool,
    validation: PropTypes.bool
}
export default HorizontalFormAsyncSelect