import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Collapse, Form, Row, Card} from "react-bootstrap";
import {useFragment} from "react-relay";
import {allContractsDropdown} from "../contracts/ContractFragments";

const PractitionerFilters = ({setFilter, contracts, open}) => {
    const allContracts = useFragment(allContractsDropdown, contracts)
    return (
            <Collapse in={open} className={"mt-2"}>
                <div>
                <Card.Header className={"bg-light"}>
                    <Col md={2}>
                    <Form.Group controlId={"legalContract.name"}>
                        <Form.Label>Contract</Form.Label>
                        <Form.Select size="sm" onChange={(e) => {setFilter("legalContract.name", e.target.value)}}>
                            <option value={""}>All</option>
                            {allContracts.map((c) => <option key={c.id} value={c.name}>{c.name}</option>)}
                        </Form.Select>
                    </Form.Group>
                    </Col>
                </Card.Header>
                </div>
            </Collapse>
    )
}
PractitionerFilters.defaultProps = {}
PractitionerFilters.propTypes = {}
export default PractitionerFilters