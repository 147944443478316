import React from 'react';
import DetailsPageHeader from "../../../components/common/headers/DetailsPageHeader";
import {useLoaderData, useParams} from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import {usePreloadedQuery} from "react-relay";
import ContractLineOfServiceInfo from "./ContractLineOfServiceInfo";
import DeletePanel from "../../../components/common/repeated-page-panels/DeletePanel";

const ContractLineOfServiceDetails = () => {
    const loader = useLoaderData();
    const {lineOfServiceId} = useParams();
    const data = usePreloadedQuery(graphql`
        query ContractLineOfServiceDetailsQuery($id: ID!) {
            node(id: $id) {
                ... on LegalContract {
                    id
                    linesOfService {
                        id
                        lineOfService {
                            name
                        }
                        ...ContractLineOfServiceInfo_ContractLineOfService
                    }
                }
            }
        }
    `, loader.contractLineOfServiceDetailsQuery)
    const lineOfService = data.node.linesOfService.find((l) => {return l.id === lineOfServiceId});
    const deleteMutation = graphql`
        mutation ContractLineOfServiceDetailsMutation($id: ID!) {
            deleteNode(input: {id: $id}) {
                id
            }
        }
    `
    return (
        <>
            <DetailsPageHeader iconText={"Contract Line of Service"} iconType={"pen-nib"} title={lineOfService.lineOfService.name} />
            <ContractLineOfServiceInfo contractLineOfService={lineOfService} />
            <DeletePanel mutation={deleteMutation} redirectUrl={"/contracts/" + data.node.id} thingToDelete={"Contract Line of Service"} id={lineOfServiceId}>
                In order to delete a Contract Line of Service, there must be not associated data. 
            </DeletePanel>
        </>
    )
}
export default ContractLineOfServiceDetails