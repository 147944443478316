import React from 'react';
import PropTypes from 'prop-types';
import {Card, OverlayTrigger, Tooltip, Row, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const KeySection = ({keys, tooltip}) => {
    return (
        <Row className={"mb-1"}>
            <Col md={3}>
                <Card className={"bg-200"}>
                    <Card.Header className={"pb-0"}>
                        <h3 className={"h6 mb-0 mt-0"}>Key
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip style={{ position: 'fixed' }}>
                                        {tooltip}
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <FontAwesomeIcon 
                                        icon={['far', 'question-circle']} 
                                        transform="shrink-1" 
                                        className="ms-1 text-400"
                                    />
                                </span>
                            </OverlayTrigger>
                        </h3>
                    </Card.Header>
                    <Card.Body className={"pb-0"}>
                        <dl>
                            {keys.sort((a,b) => (a.displayOrder - b.displayOrder)).map(x => {
                                return <Row key={x.id}>
                                    <Col>
                                        <dt className={"me-2"}>{x.propertyName}</dt>
                                    </Col>
                                    <Col>
                                        <dd>{x.proposedValue ? x.proposedValue : "N/A"}</dd>
                                    </Col>
                                </Row>
                            })}
                        </dl>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}
KeySection.defaultProps = {}
KeySection.propTypes = {}
export default KeySection