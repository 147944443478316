/**
 * @generated SignedSource<<8679efa94ead480032c693d459c4918d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressLine1"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressLine2"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "city"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "legalContractId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postalCode"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationMutationGroup",
    "kind": "LinkedField",
    "name": "location",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "addressLine1",
                "variableName": "addressLine1"
              },
              {
                "kind": "Variable",
                "name": "addressLine2",
                "variableName": "addressLine2"
              },
              {
                "kind": "Variable",
                "name": "city",
                "variableName": "city"
              },
              {
                "kind": "Variable",
                "name": "description",
                "variableName": "description"
              },
              {
                "kind": "Variable",
                "name": "legalContractId",
                "variableName": "legalContractId"
              },
              {
                "kind": "Variable",
                "name": "name",
                "variableName": "name"
              },
              {
                "kind": "Variable",
                "name": "postalCode",
                "variableName": "postalCode"
              },
              {
                "kind": "Variable",
                "name": "state",
                "variableName": "state"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "LocationModifyPayloadType",
        "kind": "LinkedField",
        "name": "create",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Location",
            "kind": "LinkedField",
            "name": "location",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewLocationModalMutation",
    "selections": (v8/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewLocationModalMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "e24d328b9322145304894a42da50e68d",
    "id": null,
    "metadata": {},
    "name": "NewLocationModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewLocationModalMutation(\n  $name: String!\n  $description: String!\n  $addressLine1: String!\n  $addressLine2: String!\n  $city: String!\n  $state: String!\n  $postalCode: String!\n  $legalContractId: ID!\n) {\n  location {\n    create(input: {name: $name, description: $description, addressLine1: $addressLine1, addressLine2: $addressLine2, city: $city, state: $state, postalCode: $postalCode, legalContractId: $legalContractId}) {\n      location {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "802bdb2ed30817c096a508d7a1a478bc";

export default node;
