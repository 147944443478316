/**
 * @generated SignedSource<<9808eef2e18ee73728078cb27d737988>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "LegalContract",
  "kind": "LinkedField",
  "name": "legalContract",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "middleInitial",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameSuffix",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nickName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PractitionersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Practitioner",
        "kind": "LinkedField",
        "name": "allPractitioners",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PractitionerVersion",
            "kind": "LinkedField",
            "name": "currentVersion",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "allLegalContracts",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContractFragments_AllContractsDropdown"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PractitionersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Practitioner",
        "kind": "LinkedField",
        "name": "allPractitioners",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PractitionerVersion",
            "kind": "LinkedField",
            "name": "currentVersion",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "allLegalContracts",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e9607afe37b65856fb6a749200194ee",
    "id": null,
    "metadata": {},
    "name": "PractitionersQuery",
    "operationKind": "query",
    "text": "query PractitionersQuery {\n  allPractitioners {\n    id\n    legalContract {\n      id\n      name\n    }\n    currentVersion {\n      lastName\n      firstName\n      middleInitial\n      nameSuffix\n      nickName\n      id\n    }\n  }\n  allLegalContracts {\n    ...ContractFragments_AllContractsDropdown\n    id\n  }\n}\n\nfragment ContractFragments_AllContractsDropdown on LegalContract {\n  id\n  name\n}\n"
  }
};
})();

node.hash = "46a53561d198488da89198add658349d";

export default node;
