/**
 * @generated SignedSource<<564a42ba6905b5cf81d53562d85d0694>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressLine1"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressLine2"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "city"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countyId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "latitude"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "legalContractId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "longitude"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postalCode"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v10 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "addressLine1",
        "variableName": "addressLine1"
      },
      {
        "kind": "Variable",
        "name": "addressLine2",
        "variableName": "addressLine2"
      },
      {
        "kind": "Variable",
        "name": "city",
        "variableName": "city"
      },
      {
        "kind": "Variable",
        "name": "countyId",
        "variableName": "countyId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "latitude",
        "variableName": "latitude"
      },
      {
        "kind": "Variable",
        "name": "legalContractId",
        "variableName": "legalContractId"
      },
      {
        "kind": "Variable",
        "name": "longitude",
        "variableName": "longitude"
      },
      {
        "kind": "Variable",
        "name": "postalCode",
        "variableName": "postalCode"
      },
      {
        "kind": "Variable",
        "name": "state",
        "variableName": "state"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  (v11/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LocationInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationMutationGroup",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "LocationModifyPayloadType",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "location",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "LocationInfo_Location"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v9/*: any*/),
      (v8/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "LocationInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationMutationGroup",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "LocationModifyPayloadType",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "location",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalContract",
                    "kind": "LinkedField",
                    "name": "legalContract",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LocationVersion",
                    "kind": "LinkedField",
                    "name": "currentVersion",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addressLine1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addressLine2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "County",
                    "kind": "LinkedField",
                    "name": "county",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "longitude",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f30dd57509b77938ba147cefe63f417",
    "id": null,
    "metadata": {},
    "name": "LocationInfoMutation",
    "operationKind": "mutation",
    "text": "mutation LocationInfoMutation(\n  $id: ID!\n  $addressLine1: String!\n  $addressLine2: String!\n  $city: String!\n  $state: String!\n  $postalCode: String!\n  $legalContractId: ID!\n  $longitude: DecimalInput\n  $latitude: DecimalInput\n  $countyId: StringInput\n) {\n  location {\n    modify(input: {id: $id, addressLine1: $addressLine1, addressLine2: $addressLine2, city: $city, state: $state, postalCode: $postalCode, legalContractId: $legalContractId, longitude: $longitude, latitude: $latitude, countyId: $countyId}) {\n      location {\n        ...LocationInfo_Location\n        id\n      }\n    }\n  }\n}\n\nfragment LocationInfo_Location on Location {\n  id\n  legalContract {\n    id\n  }\n  currentVersion {\n    name\n    id\n  }\n  addressLine1\n  addressLine2\n  city\n  state\n  postalCode\n  county {\n    id\n  }\n  longitude\n  latitude\n}\n"
  }
};
})();

node.hash = "4dd385773ea4de7a408b2f0ee1559617";

export default node;
