import React from 'react';
import PropTypes from 'prop-types';
import {ButtonGroup, Button} from "react-bootstrap";
import {useFormContext, useWatch} from "react-hook-form";
import {useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";

const changeStatusMutation = graphql`
mutation AcceptRejectMutation($id: ID!, $status: String!) {
    rosterLoad {
        modifyRosterLoadComponent(input: {componentId: $id, status: $status}) {
            component {
                id
                status
            }
        }
    }
}`
const AcceptReject = ({controlId}) => {
    const {setValue, trigger, getValues,clearErrors} = useFormContext()
    const statusKey = controlId + ".status"
    const status = useWatch({name:statusKey});
    const id = getValues(controlId + ".id");
    const [commitMutation, isInFlight] = useMutation(changeStatusMutation);
    const handleAcceptClick = async () => {
        const result = await trigger(controlId)
        if(result) {
            commitMutation({variables: {
                    id: id,
                    status: "Accepted"
                }, onCompleted: (data) => {
                    if (data.rosterLoad != null) {
                        setValue(statusKey,"Accepted");
                    }
                }})
        }
    }
    const handleRejectClick = () => {
        commitMutation({variables: {
                id: id,
                status: "Rejected"
            }, onCompleted: () => {
                clearErrors(controlId);
                setValue(statusKey, "Rejected");
            }})
    }
    const handleUndecidedClick = () => {
        commitMutation({variables: {
            id: id,
                status: "Undecided"
            }, onCompleted: () => {
                setValue(statusKey, "Undecided")
            }})
    }
    return (<>
            {status === "Undecided" &&
        <ButtonGroup size={"sm"}>
            <Button variant={"success"} onClick={handleAcceptClick}>Accept</Button>
            <Button variant={"danger"} onClick={handleRejectClick}>Reject</Button>
        </ButtonGroup>
}
            {status !== "Undecided" &&
                <Button variant={"primary"} size={"sm"} onClick={handleUndecidedClick}>Edit</Button>}
        </>
    )
}
AcceptReject.propTypes = {
    controlId: PropTypes.string.isRequired
}
export default AcceptReject