import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import {useLoaderData} from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";
import {LinkCell} from "../../components/common/advance-table/CustomCells";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {useUser} from "../../hooks/useUser";
import NewLocationModal from "./NewLocationModal";
import {FormatAddress} from "../../helpers/utils";

const cols = [
    {
        accessor: 'currentVersion.name',
        Header: 'Name',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { currentVersion, id } = rowData.row.original;
            return <LinkCell name={currentVersion.name} to={"/locations/" + id} hasAvatar />
        }
    },
    {
        accessor: 'legalContract.name',
        Header: 'Contract',
        cellProps: { className: 'py-2' },
        Cell: rowData => {
            const {legalContract} = rowData.row.original;
            return <LinkCell name={legalContract.name} to={"/contracts/" + legalContract.id} />
        }

    },
    {
        accessor: 'addressLine1',
        Header: 'Address',
        Cell: rowData => {
            const address = rowData.row.original;
            return <>{FormatAddress(address.addressLine1, address.addressLine2, "", 
                address.city, address.state, address.postalCode)}</>
        }
    },
    {
        //todo probably some kind of truncation/word wrapping
        accessor: 'currentVersion.description',
        Header: 'Description'
    }
];
const Locations = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
    query LocationsQuery {
        allLocations {
            id
            legalContract {
                id
                name
            }
            currentVersion {
                name
                description
            }
            addressLine1
            addressLine2
            city
            state
            postalCode
        }
        allLegalContracts {
            ...ContractFragments_AllContractsDropdown
        }
        allCounties {
            ...Fragments_AllCountiesDropdown
        }
    }`, loader.locationQuery)
    const {hasPermission} = useUser();
    const [showModal, setShowModal] = useState(false);
    return (<>
            <AdvanceTableWrapper
                columns={cols}
                data={data.allLocations}
                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <IndexTableHeader name={"Locations"} 
                                          onNewClick={() => {setShowModal(true)}} 
                                          table
                                          showNew={hasPermission("Locations.Create")}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter table
                                            rowCount={data.allLocations.length}
                                            rowInfo
                                            navButtons 
                                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewLocationModal showModal={showModal} 
                              contracts={data.allLegalContracts}
                              counties={data.allCounties}
                              onClose={() => {setShowModal(false)}} />
        </>
    );
};

export default Locations;
