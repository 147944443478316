import React, {useEffect} from 'react';
import {useRevalidator} from "react-router-dom";
import {Card, Spinner} from "react-bootstrap";

const AnalyzingRosterLoad = (props) => {
    const revalidator = useRevalidator();
    useEffect(() => {
        const interval = setInterval(() => {
            revalidator.revalidate();
        }, 10000)
        return () => clearInterval(interval);
    }, [])
    return (
        <Card>
            <Card.Body className={"bg-light"}>
                <Card.Title><Spinner as={"span"} role={"status"} className={"me-2"} size={"sm"} /> We are currently analyzing your file. </Card.Title>
                <Card.Text>Depending on the file size, it could take a while. </Card.Text>
            </Card.Body>
        </Card>
    )
}
export default AnalyzingRosterLoad