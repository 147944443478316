import React from 'react';
import PropTypes from 'prop-types';
import {useFragment} from "react-relay";
import {allContractsDropdown} from "../contracts/ContractFragments";
import graphql from "babel-plugin-relay/macro";
import {useForm} from "react-hook-form";
import NewModal from "../../components/common/modals/NewModal";
import HorizontalFormSelect from "../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";

const NewPractitionerModal = ({showModal, onClose, contracts}) => {
    const mutation = graphql`
    mutation NewPractitionerModalMutation($contractId: ID!, $firstName: String!, $lastName: String!, $npi: String!, 
        $middleInitial: String!, $nameSuffix: String!, $nickName: String!) {
        practitioner {
            create(input: {legalContractId: $contractId, firstName: $firstName, lastName: $lastName, middleInitial: $middleInitial,
            nameSuffix: $nameSuffix, nickName: $nickName, npi: $npi}) {
                practitioner {
                    id
                }
            }
        }
    }`
    const allContracts = useFragment(allContractsDropdown, contracts).map(c => {return {value: c.id, label: c.name}});
    const defaultValues = {
        firstName:'',
        lastName:'',
        middleInitial: '',
        nameSuffix:'',
        nickName:'',
        npi:'',
        contractId:''
    }
    const methods = useForm({defaultValues: defaultValues})
    return (
        <NewModal showModal={showModal} 
                  onClose={onClose} 
                  methods={methods} 
                  title={"New Provider"}
                  redirectUrl={"/practitioners/"}
                  mutation={mutation}
                  returnedIdKey={"practitioner.create.practitioner.id"}
        >
            <HorizontalFormSelect options={allContracts} controlId={"contractId"} label={"Contract"} validation={{required:"Contract is required"}} />
            <HorizontalFormInput controlId={"npi"} label={"Npi"} validation={{required:"Npi is required"}} />
            <HorizontalFormInput controlId={"firstName"} label={"First Name"} validation={{required:"First Name is required"}} />
            <HorizontalFormInput controlId={"middleInitial"} label={"Middle Initial"} validation={{maxLength: 1}} />
            <HorizontalFormInput controlId={"lastName"} label={"Last Name"} validation={{required:"Last Name is required"}} />
            <HorizontalFormInput controlId={"nameSuffix"} label={"Name Suffix"} />
            <HorizontalFormInput controlId={"nickName"} label={"Nick Name"} />
        </NewModal>
    )
}

NewPractitionerModal.propTypes = {}
export default NewPractitionerModal