import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {addEditAndDeleteCells, DateCell} from "../../../components/common/advance-table/CustomCells";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {useUser} from "../../../hooks/useUser";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import NewLocationContactPointModal from "./NewLocationContactPointModal";
import EditLocationContactPointModal from "./EditLocationContactPointModal";
import DeleteModal from "../../../components/common/modals/DeleteModal";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";

const columns = [
    {accessor: 'contactPointSystemCode.display', Header: 'System'},
    {accessor: 'contactPointUseCode.display', Header:'Use'},
    {accessor: 'value', Header: 'Value'},
    {accessor: 'effectiveFrom', Header: "Effective From", Cell: DateCell},
    {accessor:'effectiveTo', Header:'Effective To', Cell: DateCell}
    
]
const LocationContactPoints = ({contactPoints, locationId, contactPointSystemCodes, contactPointUseCodes}) => {
    const mutation = graphql`
    mutation LocationContactPointsDeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            owner {
                ... on Location {
                    id
                    contactPoints {
                        id
                    }
                }
            }
        }
    }`
    const data = useFragment(graphql`
        fragment LocationContactPoints_ContactPoints on LocationContactPoint @relay(plural: true) {
            id
            contactPointSystemCode {
                id
                display
            }
            contactPointUseCode {
                id
                display
            }
            value
            effectiveFrom
            effectiveTo
        }`, contactPoints)
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] =  useState(false);
    const [selectedContactPoint, setSelectedContactPoint] = useState(null);
    
    const handleEditClick = (data) => {
        setSelectedContactPoint(data);
        setShowEditModal(true);
    }
    const handleDeleteClick = (data) => {
        setSelectedContactPoint(data);
        setShowDeleteModal(true);
    }
    
    const cols = useMemo(() => {
        const canEdit = hasPermission("LocationContactPoints.Update");
        const canDelete = hasPermission("LocationContactPoints.Delete");
        return addEditAndDeleteCells(canEdit, canDelete, columns, handleEditClick, handleDeleteClick)
    }, [hasPermission])
     return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                         title={"Contact Points"}
                                         table
                                         showNew={hasPermission("LocationContactPoints.Create")} />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewLocationContactPointModal showModal={showNewModal} 
                                          onClose={() => {setShowNewModal(false)}}
                                          locationId={locationId}
                                          contactPointSystemCodes={contactPointSystemCodes}
                                          contactPointUseCodes={contactPointUseCodes}
            />
            {selectedContactPoint && <EditLocationContactPointModal showModal={showEditModal}
                                                                    onClose={() => {setShowEditModal(false)}}
                                                                    selectedContactPoint={selectedContactPoint}
                                                                    contactPointSystemCodes={contactPointSystemCodes}
                                                                    contactPointUseCodes={contactPointUseCodes}
            />}
            {selectedContactPoint && <DeleteModal mutation={mutation} 
                                                  onClose={() => {setShowDeleteModal(false)}} 
                                                  thingToDelete={"Contact Point"} 
                                                  showModal={showDeleteModal} id={selectedContactPoint.id}>
                <p>Delete this Contact Point: {selectedContactPoint.contactPointSystemCode.display} {selectedContactPoint.value}</p>
            </DeleteModal>}
        </>
    )
}
LocationContactPoints.propTypes = {
    contactPoints: PropTypes.array.isRequired,
    locationId: PropTypes.string.isRequired,
    contactPointSystemCodes: PropTypes.array.isRequired,
    contactPointUseCodes: PropTypes.array.isRequired
}
export default LocationContactPoints