/**
 * @generated SignedSource<<06efada7ab3280f7b159008361dcfd30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "legalContractId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "legalContractId",
        "variableName": "legalContractId"
      },
      {
        "kind": "Variable",
        "name": "practitionerId",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PractitionerInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerMutationGroup",
        "kind": "LinkedField",
        "name": "practitioner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "PractitionerModifyPayload",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Practitioner",
                "kind": "LinkedField",
                "name": "practitioner",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PractitionerInfo_Practitioner"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PractitionerInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerMutationGroup",
        "kind": "LinkedField",
        "name": "practitioner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "PractitionerModifyPayload",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Practitioner",
                "kind": "LinkedField",
                "name": "practitioner",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalContract",
                    "kind": "LinkedField",
                    "name": "legalContract",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PractitionerVersion",
                    "kind": "LinkedField",
                    "name": "currentVersion",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "middleInitial",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "nickName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "qualification",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "nameSuffix",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8a72f2de0ae389a0caa2baf028eae14",
    "id": null,
    "metadata": {},
    "name": "PractitionerInfoMutation",
    "operationKind": "mutation",
    "text": "mutation PractitionerInfoMutation(\n  $id: ID!\n  $legalContractId: ID!\n) {\n  practitioner {\n    modify(input: {practitionerId: $id, legalContractId: $legalContractId}) {\n      practitioner {\n        ...PractitionerInfo_Practitioner\n        id\n      }\n    }\n  }\n}\n\nfragment PractitionerInfo_Practitioner on Practitioner {\n  id\n  legalContract {\n    id\n    name\n  }\n  currentVersion {\n    firstName\n    lastName\n    middleInitial\n    nickName\n    qualification\n    nameSuffix\n    id\n  }\n}\n"
  }
};
})();

node.hash = "cf6c48ed0afc15345197ae4d3842c360";

export default node;
