/**
 * @generated SignedSource<<c1c193dd46be99ebd7654ee1ceb062bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Fragments_AllGendersDropdown",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayOrder",
      "storageKey": null
    }
  ],
  "type": "Gender",
  "abstractKey": null
};

node.hash = "cc12d2305f1fe04c89423558c6a0bc96";

export default node;
