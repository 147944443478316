import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import AddAccordionItem from "../components/AddAccordionItem";
import {useCurrentValue} from "../components/useCurrentValue";
import PractitionerLocationKeySection from "./PractitionerLocationKeySection";
import {createProviderFullName} from "../../../helpers/utils";
import {useMemoizedProposedValue} from "../helpers";
const PractitionerLocationAdd = ({practitionerLocationAdded, practitionerLocationIndex, practitioner, location}) => {
    const practitionerLocationKey = "addedPractitionerLocations[" + practitionerLocationIndex + "]";
    const practitionerName = useMemo(() => {
        const firstName = practitioner.properties.find((p) => p.propertyName === "First Name")?.proposedValue;
        const lastName = practitioner.properties.find((p) => p.propertyName === "Last Name")?.proposedValue;
        const middleInitial = practitioner.properties.find((p) => p.propertyName === "Middle Initial")?.proposedValue;
        const nameSuffix = practitioner.properties.find((p) => p.propertyName === "Name Suffix")?.proposedValue
        return  createProviderFullName(firstName, lastName, middleInitial, nameSuffix);
    }, [practitioner])
    const locationName = location.properties.find((prop) => prop.propertyName === "Name")?.proposedValue;
    const effectiveDate = useMemoizedProposedValue(practitionerLocationAdded.keys, "Effective Date");
    return (
        <AddAccordionItem properties={practitionerLocationAdded.properties}
                          header={practitionerName + " at " + locationName}
                          controlId={practitionerLocationKey}
                          index={practitionerLocationIndex}
                          effectiveDate={effectiveDate}
        >
            <PractitionerLocationKeySection keys={practitionerLocationAdded.keys} practitionerName={practitionerName} locationName={locationName}  />
        </AddAccordionItem>
    )
}
PractitionerLocationAdd.propTypes = {
    practitionerLocationAdded: PropTypes.object.isRequired,
    practitionerLocationIndex: PropTypes.number.isRequired,
    practitioner: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}
export default PractitionerLocationAdd